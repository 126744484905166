import axios from 'axios';
const url = 'loans/Api/PrintingDocuments/';

export default {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async get_allTemplates() {
            try {
                const response = await axios.get(`${url}Templates/GetAll`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting templates', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async create_template({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}Templates/Add`, {
                    ...payload,
                    createBy: rootGetters['auth/getUser'].Id,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating template', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_allSignedDocuments() {
            try {
                const response = await axios.get(`${url}SignedDocument/GetAll`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting signed documents', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async create_signedDocument({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}SignedDocument/Add`, {
                    ...payload,
                    createBy: rootGetters['auth/getUser'].Id,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating signed document', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async remove_signedDocument(_, id) {
            try {
                const response = await axios.delete(`${url}SignedDocument/Remove/${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error removing signed document', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
