<template>
    <b-card no-body>
        <template v-if="!readOnly">
            <Header />
            <hr class="mb-2" />
        </template>
        <div class="d-flex flex-column bg-light p-3" style="row-gap: 12px">
            <ComplianceInformation
                v-if="applicantInfo"
                :id="applicantInfo.id"
                :customer="applicantInfo"
                type="applicant"
                title="Applicant Information"
            />
            <ComplianceInformation
                v-if="applicantSpousesInfo"
                :id="applicantSpousesInfo.id"
                :customer="applicantSpousesInfo"
                title="Applicant Spouse Information"
            />
            <ComplianceInformation
                v-if="coApplicantInfo && coApplicantInfo.id != 0"
                :id="coApplicantInfo.id"
                :customer="coApplicantInfo"
                type="coApplicant"
                title="Co-Applicant Information"
            />
            <ComplianceInformation
                v-if="coApplicantSpousesInfo"
                :id="coApplicantSpousesInfo.id"
                :customer="coApplicantSpousesInfo"
                title="Co-Applicant Spouse Information"
            />

            <ComplianceInformation
                v-if="guarantorInfo && guarantorInfo.id != 0"
                :id="guarantorInfo.id"
                :customer="guarantorInfo"
                type="guarantor"
                title="Guarantor Information"
            />
            <ComplianceInformation
                v-if="guarantorSpousesInfo"
                :id="guarantorSpousesInfo.id"
                :customer="guarantorSpousesInfo"
                title="Guarantor Spouse Information"
            />
        </div>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import ComplianceInformation from './compliance/ComplianceInformation.vue';
export default {
    components: {
        Header,
        ComplianceInformation,
    },
    name: 'ApplicationCompliance',
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        firstName: '',
        lastName: '',
        loading: false,
        scans: [],
        selectedMatch: null,
        loadingPep: false,
        pep: false,
        pepConfirm: null,
        typeRisk: null,
        decision: '',
        idType: null,
        identification: '',
        expDate: '',
    }),
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Membercheck');
        },
        applicantInfo() {
            return this.loanApplication.applicantInfo || null;
        },
        coApplicantInfo() {
            return this.loanApplication.coApplicantInfo || null;
        },
        guarantorInfo() {
            return this.loanApplication.guarantorInfo || null;
        },
        applicantSpousesInfo() {
            return this.loanApplication.applicantInfo?.additionalDetails?.spousesInfo || null;
        },
        coApplicantSpousesInfo() {
            return this.loanApplication.coApplicantInfo?.additionalDetails?.spousesInfo || null;
        },
        guarantorSpousesInfo() {
            return this.loanApplication.guarantorInfo?.additionalDetails?.spousesInfo || null;
        },
    },
    methods: {},
};
</script>
