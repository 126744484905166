var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"mb-0",attrs:{"show-empty":"","responsive":"","small":"","head-variant":"light","items":_vm.employments || [],"fields":[
        {
            key: 'employerName',
            label: 'EMPLOYER NAME',
        },
        {
            key: 'jobDescription',
            label: 'JOB DESCRIPTION',
        },
        {
            key: 'fromDate',
            label: 'HIRE DATE',
        },
        {
            key: 'toDate',
            label: 'END DATE',
        },
        {
            key: 'monthlySalary',
            label: 'MONTHLY SALARY',
        } ],"hover":""},scopedSlots:_vm._u([{key:"cell(fromDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$formatDate(data.item.fromDate))+" ")]}},{key:"cell(toDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$formatDate(data.item.toDate))+" ")]}},{key:"cell(monthlySalary)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$formatCurrency(data.item.monthlySalary))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }