<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body>
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-2" />
                </template>

                <h4>Applicant</h4>
                <div class="d-flex justify-content-between">
                    <b-form-group class="w-25 mb-2">
                        <b-input-group>
                            <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                            >
                        </b-input-group>
                    </b-form-group>
                    <div
                        class="d-flex align-items-center btn-grp"
                        v-if="
                            currentRoute.includes('/applications') &&
                            !readOnly &&
                            loanApplication.loanProcessStatusLog.currentStatusCode != '220'
                        "
                    >
                        <b-button variant="primary" v-b-modal.modal-upload-files class="d-flex align-items-center mr-0">
                            <feather type="upload" class="feather-sm"></feather>
                            <span class="ml-1">Upload File</span>
                        </b-button>
                    </div>
                </div>

                <div>
                    <!-- Data Table -->
                    <b-table
                        :style="selectBoxStyle"
                        :per-page="perPage"
                        :current-page="currentPage"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="documents"
                        :filter="filter"
                        :fields="fields"
                        hover
                    >
                        <template #cell(createDate)="data"> {{ $formatDate(data.item.createDate) }} </template>
                        <template #cell(documentDate)="data"> {{ $formatDate(data.item.documentDate) }} </template>
                        <template #cell(documentExpirationDate)="data">
                            {{ $formatDate(data.item.documentExpirationDate) }}
                        </template>
                        <template #cell(isActive)="data">
                            <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                            <b-badge variant="danger" v-else>Archived</b-badge>
                        </template>

                        <template #cell(view)="data">
                            <div class="d-flex">
                                <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                    <!-- <b-dropdown-item @click="removeDocument(data.item)">Remove</b-dropdown-item> -->
                                    <b-dropdown-item @click="goToView(data.item.sourcePath)">View</b-dropdown-item>
                                    <b-dropdown-item @click="disableDocument(data.item, !data.item.isActive)">
                                        {{ data.item.isActive ? 'Disabled' : 'Enabled' }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </template>
                    </b-table>
                    <!-- End Section -->
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="documents.length"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>

            <UploadDocumentModal @save="refreshInfo" isApplication />
        </div>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import UploadDocumentModal from '../common/UploadDocumentModal.vue';
export default {
    name: 'ApplicationDocuments',
    components: {
        Header,
        UploadDocumentModal,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        fields: [
            {
                key: 'documentTypeName',
                label: 'Document Type',
                sortable: true,
            },
            {
                key: 'customerType',
                label: 'Customer Type',
                sortable: true,
            },
            {
                key: 'createdByUserName',
                label: 'Created By',
                sortable: true,
            },
            {
                key: 'createDate',
                label: 'Upload Date',
                sortable: true,
            },

            {
                key: 'documentDate',
                label: 'Document Date',
            },
            {
                key: 'documentExpirationDate',
                label: 'Document Expiration Date',
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        file: null,
        documentType: null,
        customerType: null,
        loading: false,
        files: [],
        currentRoute: window.location.pathname,
        coAppDocs: [],
        guarantorDocs: [],
        loadingSave: false,
    }),
    computed: {
        ...mapGetters({
            loanApplication: 'loan/applications/getLoanApplication',
            optionsCustomer: 'loan/applications/getCustomerTypes',
        }),
        selectBoxStyle() {
            return {
                minHeight: this.readOnly ? 'auto' : '250px', // min-height condicional
            };
        },
        documents() {
            return this.loanApplication?.documents ?? [];
        },
    },
    methods: {
        async removeDocument(document) {
            await this.$store.dispatch('customer/documents/remove_document', document.id);
            await this.refreshInfo();
        },
        async refreshInfo() {
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.params.id);
        },
        async getData() {
            this.loading = true;
            await this.$store.dispatch('loan/applications/get_loanApplicationById', this.$route.params.id);
            this.loading = false;
        },
        goToView(path) {
            window.open(`${path}`, '_blank');
        },
        async disableDocument(document, status) {
            let data = {
                id: document.id,
                name: document.name,
                sourcePath: document.sourcePath,
                isActive: status,
            };
            await this.$store.dispatch('customer/documents/update_documentStatus', data);
            await this.getData();
        },
    },
    mounted() {
        if (!this.readOnly) {
            this.fields.push({
                key: 'view',
                label: 'View',
            });
        }
    },
    created() {
        if (!this.new) {
            this.getData();
        }
    },
};
</script>
