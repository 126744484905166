<template>
    <b-modal id="modal-upload-files" title="Upload Files" size="xl" hide-footer centered @close="clearData">
        <form @submit.prevent="uploadFiles">
            <div class="d-flex mb-2">
                <b-form-file
                    id="file"
                    placeholder="Choose files..."
                    drop-placeholder="Drop files here..."
                    accept=".docx,.png,.jpg,.pdf"
                    multiple
                    @change="addFiles"
                ></b-form-file>
            </div>

            <h5 class="mb-2 mt-4" v-if="files.length > 0">Files</h5>
            <b-table :items="files" :fields="tableFields" responsive style="">
                <template #cell(customerType)="{ item }">
                    <b-form-select
                        id="document-types"
                        :options="[{ value: null, text: 'Please select an option' }, ...optionsCustomer]"
                        size="sm"
                        v-model="item.customerType"
                        required
                    />
                </template>

                <template #cell(documentType)="{ item }">
                    <b-form-select
                        :options="[{ id: null, description: 'Please select an option' }, ...documentTypes]"
                        text-field="description"
                        value-field="id"
                        size="sm"
                        v-model="item.documentType"
                        required
                    />
                </template>

                <template #cell(documentDate)="{ item }">
                    <b-form-input type="date" size="sm" v-model="item.documentDate" required></b-form-input>
                </template>

                <template #cell(documentExpirationDate)="{ item }">
                    <b-form-input
                        type="date"
                        size="sm"
                        v-model="item.documentExpirationDate"
                        :required="isExpDateRequiredForFile(item.documentType)"
                    ></b-form-input>
                </template>

                <template #cell(actions)="{ item, index }">
                    <b-button variant="outline-primary" size="sm" class="mr-2" @click="viewFile(item)"> View </b-button>
                    <b-button variant="outline-danger" size="sm" @click="removeFile(index)"> Remove </b-button>
                </template>
            </b-table>

            <div class="d-flex justify-content-end mt-4 btn-grp">
                <b-button variant="secondary" @click="$bvModal.hide('modal-upload-files')" class="mr-2">
                    Cancel
                </b-button>
                <b-button
                    variant="primary"
                    class="d-flex align-items-center mr-0"
                    type="submit"
                    :disabled="files.length == 0 || loadingSave"
                >
                    <b-spinner small v-if="loadingSave"></b-spinner>
                    <template v-else>
                        <feather type="upload" class="feather-sm"></feather>
                        <span class="ml-1">Upload</span>
                    </template>
                </b-button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
export default {
    name: 'UploadDocument',
    props: {
        isApplication: Boolean,
    },
    data: () => ({
        loading: false,
        files: [],
        loadingSave: false,
        tableFields: [
            { key: 'name', label: 'File Name' },
            { key: 'documentType', label: 'Document Type' },
            { key: 'documentDate', label: 'Document Date' },
            { key: 'documentExpirationDate', label: 'Expiration Date' },
            { key: 'actions', label: 'Actions' },
        ],
    }),
    computed: {
        ...mapGetters({
            documentTypes: 'customer/documents/getDocumentTypes',
            optionsCustomer: 'loan/applications/getCustomerTypes',
        }),
    },
    methods: {
        clearData() {
            this.file = null;
            this.files = [];
        },
        async getDocumentTypes() {
            await this.$store.dispatch('customer/documents/get_documentTypes');
        },
        addFiles(event) {
            const newFiles = Array.from(event.target.files || []);
            newFiles.forEach((file) => {
                this.files.push({
                    name: file.name,
                    file: file,
                    documentType: null,
                    customerType: null,
                    documentDate: dayjs().format('YYYY-MM-DD'),
                    documentExpirationDate: null,
                });
            });
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        isExpDateRequiredForFile(documentTypeId) {
            const document = this.documentTypes.find((doc) => doc.id === documentTypeId);
            return document?.expirationDateRequired;
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Strip metadata if needed
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async uploadFiles() {
            this.loadingSave = true;
            for (const item of this.files) {
                const blob = await this.fileToBase64(item.file);
                const data = {
                    fileName: item.name,
                    loanApplicationId: this.isApplication ? this.$route.params.id : 0,
                    customerId: this.isApplication ? item.customerType : this.$route.params.customer,
                    documentTypeId: item.documentType,
                    documentDate: item.documentDate,
                    documentExpirationDate: item.documentExpirationDate,
                    file: blob,
                };

                await this.$store.dispatch('customer/documents/add_documentsInCDN', data);
            }
            this.$emit('save');
            this.loadingSave = false;
            this.clearData();
            this.$bvModal.hide('modal-upload-files');
        },
        async viewFile(item) {
            const objectURL = URL.createObjectURL(item.file);
            window.open(objectURL, '_blank');
        },
    },
    mounted() {},
    created() {
        this.getDocumentTypes();
        if (this.isApplication) {
            //PUSH A COLUMN after NAME CALLED APPLICATION
            this.tableFields.splice(1, 0, { key: 'customerType', label: 'Customer Type' });
        }
    },
};
</script>
