import { jwtDecode } from 'jwt-decode';

export function validatePermission(permission) {
    const token = localStorage.getItem('token');

    if (!token) return false;

    const user = jwtDecode(token);
    const session = JSON.parse(user.Session);
    const permissions = session.Authorizations;

    return permissions.includes(permission);
}
