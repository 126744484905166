export default [
    {
        name: 'Users',
        path: 'security/users',
        component: () => import('@/views/security/Users'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Users-GetAll'],
        },
    },
    {
        name: 'Roles',
        path: 'security/roles',
        component: () => import('@/views/security/Roles'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Roles-GetAll'],
        },
    },
];
