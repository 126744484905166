<template>
    <div>
        <b-button @click="generatePDF">Descargar PDF</b-button>
    </div>
</template>

<script>
import jsPDF from 'jspdf';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paragraphs: [
                {
                    text: 'OVEREENKOMST VAN GELDLENING',
                    align: 'center',
                    fontSize: 14,
                    bold: true,
                },
                {
                    text: `Contractnummer: ${this.data.loanNumber}`,
                    align: 'center',
                    fontSize: 10,
                },
                {
                    text: 'Partijen',
                },
                {
                    text: `1. de vennootschap met beperkte aansprakelijkheid MACK’S TOTAL FINANCE VBA, gevestigd te Oranjestad aan de Adriaan Laclé boulevard nr. 28 (Rutena Mall Lok. 7), rechtsgeldig vertegenwoordigd door ${this.data.loanOfficer}.`,
                },
                {
                    text: 'hierna te noemen: Total Finance',
                },
                {
                    text: `en`,
                },
                {
                    text: `2. ${this.data.applicantName} en ${this.data.coApplicantName}, wonende te ${this.data.applicantAddress} (ID nummer ${this.data.applicantIdNumber})`,
                },
                {
                    text: 'hierna (in enkelvoud) te noemen: de Kredietnemer',
                },

                {
                    text: 'OMDAT',
                },
                {
                    text: 'De Kredietnemer geld wil lenen van Total Finance en Total Finance bereid is om onder de volgende voorwaarden geld aan Kredietnemer uit te lenen zijn partijen het volgende OVEREENGEKOMEN:',
                },
                {
                    text: 'OVEREENGEKOMEN:',
                },
                {
                    text: `1. Total Finance verleent hierbij aan Kredietnemer, die dat aanvaardt, een geldlening van (a) Afl.${this.data.principalBalance}.`,
                },
                {
                    text: `2. De Kredietnemer betaalt over het hierboven genoemde bedrag van de hoofdsom een effectieve rente van ${this.data.apr} %(APR) per jaar. Het aan rente te betalen bedrag over de hele looptijd van de overeenkomst bedraagt (b) Afl. ${this.data.interest}.`,
                },
                {
                    text: `3. Het totaalbedrag dat de Kredietnemer tijdens de looptijd van de overeenkomst aan Total Finance, inclusief rente en kosten betaalt bedraagt (a + b = c) Afl. ${this.data.totalInterestPrincipal}. Als er meer Kredietnemers zijn, zijn zij hoofdelijk tot betaling verplicht.`,
                },
                {
                    text: `4. De Kredietnemer betaalt per maand Afl. ${this.data.monthlyPayment} aan aflossing en rente gedurende ${this.data.loanTermInMonths} aaneengesloten maanden, met een laatste betaling van ${this.data.lastMonthlyPayment} uiterlijk ${this.data.lastPaymentDate}.`,
                },
                {
                    text: `5. Betaling vindt plaats steeds uiterlijk op de 1ste dag van elke maand, voor het eerst uiterlijk die dag van ${this.data.firstPaymentDate} Betaling moet plaats vinden door overschrijving naar, of storting op de bankrekening van Total Finance nummer 2568710190 bij de Aruba Bank bank met vermelding van het hierboven staande contractnummer, of door contante betaling op het kantoor van Total Finance. Total Finance accepteert geen cheques.`,
                },

                {
                    pageBreak: true,
                },
                {
                    text: `7. De Kredietnemer verleent bij deze aan Total Finance, voor zover wettelijk toegestaan (zie artikel 7A:1614g lid 2 Burgerlijk Wetboek/artikel 112 en 114 lid 1 Landsverordening materieel ambtenarenrecht), tot meerdere zekerheid voor de voldoening van alles wat de Kredietnemer volgens deze overeenkomst of de wet aan Total Finance verschuldigd is of zal worden een stil pandrecht op zijn/haar vorderingen op de werkgever(s) van de Kredietnemer in verband met verdiend of nog te verdienen loon, salaris, provisie of ander inkomen uit arbeid, tot een derde van dat inkomen, en verleent Total Finance recht om, als de Kredietnemer de verplichtingen niet nakomt, op deze manier tot inning van de overgedragen vorderingen over te gaan.`,
                },
                {
                    text: `8. Op de overeenkomst zijn de Algemene Voorwaarden van toepassing die op de achterkant van dit document staan vermeld. De Algemene Voorwaarden staan ook op de website van Total Finance. Alleen deze Algemene Voorwaarden beheersen de overeenkomst. Eventuele door de Kredietnemer gebruikte Algemene Voorwaarden worden door ondertekening van deze overeenkomst uitdrukkelijk niet van toepassing verklaard. Alleen de Nederlandse tekst van deze overeenkomst is voor de uitleg daarvan doorslaggevend. `,
                },
                {
                    text: `Aruba, ${this.data.contractDate}`,
                },
                {
                    text: `${this.data.applicantName}`,
                },
                {
                    text: `_________________________`,
                },
                {
                    text: `handtekening Kredietnemer 1`,
                },
                {
                    text: `namens Mack’s Total Finance VBA`,
                },
                {
                    text: `${this.data.loanOfficerName}`,
                },
                {
                    text: `_________________________`,
                },
                {
                    text: `handtekening medewerker`,
                },
                {
                    text: `ALGEMENE VOORWAARDEN`,
                    align: 'center',
                    fontSize: 14,
                    bold: true,
                },
                {
                    text: `1. De Kredietnemer garandeert dat hij bevoegd is om de overeenkomst van geldlening met Mack’s Total Finance VBA (hierna: Total Finance) te sluiten.`,
                    fontSize: 10,
                },
                {
                    text: `2. In verband met de overeenkomst kiest de Kredietnemer woonplaats op het adres dat staat vermeld in de overeenkomst van geldlening. Als de Kredietnemer verhuist moet hij/zij de nieuwe woonplaats schriftelijk aan Total Finance doorgeven. Zolang dat niet gebeurt blijft de Kredietnemer woonplaats houden op het laatste bij Total Finance bekende adres. Total Finance mag er ook altijd vanuit gaan dat de Kredietnemer woont op het adres waarop hij/zij staat ingeschreven in het bevolkingsregister (Censo). `,
                },

                {
                    pageBreak: true,
                },

                {
                    text: `3. Als de lening is verstrekt aan meer dan een kredietnemer zijn alle Kredietnemers hoofdelijk verbonden. Alle Kredietnemers zijn tegenover Total Finance hoofdelijk voor het geheel verbonden voor alle verplichtingen, nu en in de toekomst, die voortvloeien uit de overeenkomst van geldlening voor elke (andere) Kredietnemer. Elke Kredietnemer doet afstand van alle verweermiddelen en rechten aan hoofdelijk verbondenen toekomend. Als subrogatie optreedt bij betaling door één of meer van de Kredietnemers, zal deze subrogatie niet benut mogen worden in het nadeel van Total Finance. De gerechtigde tot deze subrogatie stelt de regresvordering achter ten opzichte van die van Total Finance.`,
                },
                {
                    text: '4. De Kredietnemer heeft geen recht op verrekening (aftrekken) van zijn betalingsverplichting met een eventuele vordering van hem op Total Finance. Hij mag zijn betalingsverplichting ook niet opschorten (uitstellen). Total Finance heeft steeds het recht om betalingen aan de Kredietnemer en/of afboekingen op de vordering op de Kredietnemer die het gevolg zijn van een administratieve vergissing of een rekenfout van Total Finance te corrigeren.',
                },
                {
                    text: `5. Behalve op volledige schadevergoeding kan Total Finance op de Kredietnemer in ieder geval aanspraak maken op betaling van een boete van Afl. 35,   voor elke termijn die niet op tijd is betaald. Als Total Finance tot incasso (het eisen van betaling) overgaat is de Kredietnemer aansprakelijk voor alle daarmee verband houdende kosten. Het bedrag daarvan wordt vastgesteld op Afl.15,   voor de eerste incassobrief, Afl. 50,   voor de tweede incassobrief en Afl. 75,   voor de derde incassobrief. Als de vordering in handen wordt gesteld van de afdeling Bijzonder Beheer (Special Assets) is de Kredietnemer 10% van het dan openstaande bedrag aan incassokosten verschuldigd. Total Finance is niet verplicht incassobrieven te sturen voordat zij een incassoprocedure bij het Gerecht in Eerste Aanleg start. Total Finance is steeds gerechtigd betaling van daadwerkelijk gemaakte buitengerechtelijke incasso te vorderen.`,
                },
                {
                    text: `6. Betalingen worden in volgorde eerst in mindering gebracht op de openstaande boete, de kosten en de rente en pas daarna op de hoofdsom.`,
                },
                {
                    text: `7. Als een of meer maandtermijnen of de boete niet of niet op tijd betaald wordt, heeft Total Finance het recht om de overeenkomst onmiddellijk te ontbinden en het restant van de hoofdsom, de dan verschuldigde rente, de kosten en de boete op te eisen, te vermeerderen met de in de overeenkomst van geldlening overeengekomen rente, steeds over het restant van het totaal van die bedragen vanaf de dag dat dit bedrag wordt opgeëist totdat alles is betaald. Total Finance kan bovendien schadevergoeding eisen voor zover zij meer schade lijdt.`,
                },
                {
                    text: `8. Daarnaast heeft Total Finance het recht om de overeenkomst op te zeggen in de volgende gevallen: als er beslag wordt gelegd op vermogen van de Kredietnemer, als het faillissement van de Kredietnemer wordt aangevraagd of hij/zij surseance van betaling aanvraagt, als de Kredietnemer feitelijk geen woonplaats in Aruba meer heeft, als de Kredietnemer in strafrechtelijke verzekering wordt gesteld, als ten aanzien van de Kredietnemer lijfsdwang wordt toegepast, als de Kredietnemer onder curatele wordt geplaatst of het vermogen geheel of deels onder bewind wordt gesteld, als de Kredietnemer Total Finance bewust verkeerd heeft ingelicht over omstandigheden waarvan de Kredietnemer redelijkerwijze moet weten dat die van belang zijn voor de beoordeling van de kredietwaardigheid van de Kredietnemer; als de Kredietnemer niet binnen een redelijke termijn voldoet aan het verstrekken van door Total Finance gevraagde (aanvullende) zekerheid in verband met de betalingsverplichting. Opzegging van de overeenkomst leidt tot dezelfde verplichting van de Kredietnemer als ontbinding daarvan. `,
                },
                {
                    pageBreak: true,
                },
                {
                    text: `9. Als de overeenkomst wordt beëindigd anders dan op grond van de hiervoor genoemde gevallen is de Kredietnemer een vergoeding verschuldigd die gelijk is aan een derde (1/3) van de op het moment van beëindiging niet verdiende kredietvergoeding met een minimum van zes (6) maanden of zoveel minder als de overeenkomst zonder voortijdige beëindiging daarvan gelopen zou hebben. `,
                },
                {
                    text: `10. In het geval van overlijden van de Kredietnemer later dan zes maanden na ondertekening van dit contract maar voor het bereiken van de 65-jarige leeftijd verleent Total Finance kwijtschelding voor het volledige dan nog uitstaande bedrag. Total Finance is niet verplicht tot kwijtschelding als de Kredietnemer informatie heeft achtergehouden waarvan hij redelijkerwijs moest begrijpen dat die voor Total Finance van belang was voor het beoordelen van het overlijdensrisico. Total Finance is ook niet verplicht tot kwijtschelding als de Kredietnemer een of meer van de verplichtingen uit deze overeenkomst niet is nagekomen. `,
                },
                {
                    text: `11. Total Finance is niet verplicht om informatie te verschaffen over de lening behalve als zij daartoe volgens de wet verplicht is en als de Kredietnemer daar zelf om vraagt. Total Finance behoudt zich het recht voor om, spontaan of daarnaar gevraagd, informatie te verschaffen over deze lening aan banken of andere (rechts)personen aan wie door een van de Centrale Banken binnen het Koninkrijk der Nederlanden toestemming of ontheffing van een verbod is verleend om geld uit te lenen of aan de Centrale Bank zelf. De Kredietnemer verleent Total Finance toestemming om informatie in te winnen over de financiële betrouwbaarheid van de Kredietnemer.`,
                },
            ],
        };
    },
    methods: {
        generatePDF() {
            const doc = new jsPDF({
                format: 'a4',
                unit: 'mm',
            });

            // Add paragraphs content first
            let y = 20;
            const pageWidth = doc.internal.pageSize.getWidth();

            this.paragraphs.forEach((paragraph) => {
                if (paragraph.pageBreak) {
                    doc.addPage();
                    y = 20;
                    //next item after page break
                    return;
                }

                doc.setFont('Helvetica', paragraph.bold ? 'bold' : 'normal');
                //set line height
                doc.setLineHeightFactor(1.5);

                if (paragraph.fontSize) {
                    doc.setFontSize(paragraph.fontSize);
                }

                let x = 10;
                if (paragraph.align) {
                    const textWidth = doc.getTextWidth(paragraph.text);
                    x = (pageWidth - textWidth) / 2;
                }

                doc.text(paragraph.text, x, y, {
                    maxWidth: 180,
                });

                const calculateLines = paragraph.text.length / 110;
                const linesRoundUp = Math.ceil(calculateLines);

                y += 5 * linesRoundUp + 5;
            });

            // Save the PDF
            doc.save('loan-agreement.pdf');
        },
    },
};
</script>
