import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            employments: [],
        };
    },
    getters: {
        getEmployments(state) {
            return state.employments;
        },
    },
    mutations: {
        SET_EMPLOYMENTS(state, employments) {
            state.employments = employments;
        },
    },
    actions: {
        async get_employments({ commit }, payload) {
            try {
                const response = await axios.get(
                    `customers/Api/Employments/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&customerId=${payload.customerId}`,
                );
                commit('SET_EMPLOYMENTS', response.data.dataResult);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting employments', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_employmentJobVerifications() {
            try {
                const response = await axios.get(`customers/Api/Employments/JobVerifications/GetAll`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting employments', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_employeesByCustomerId(_, id) {
            try {
                const response = await axios.get(
                    `customers/Api/Employments/JobVerifications/GetEmployeesByCustomerId/${id}`,
                );
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting employees', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_employmentById(_, payload) {
            try {
                const response = await axios.get(`customers/Api/Employments/GetById/${payload}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async add_employment({ rootGetters }, payload) {
            try {
                const response = await axios.post(`customers/Api/Employments/Add`, {
                    ...payload,
                    clasificationId: 1,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_employment({ rootGetters }, payload) {
            try {
                const response = await axios.put(`customers/Api/Employments/Update`, {
                    ...payload,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                    clasificationId: 1,
                });
                this._vm.$bvToast.toast('Employment updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async delete_employment(_, id) {
            try {
                const response = await axios.delete(`customers/Api/Employments/Remove/${id}`);
                this._vm.$bvToast.toast('Employment deleted successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error deleting employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_employmentStatus({ rootGetters }, payload) {
            try {
                let data = payload;
                data.modifiedBy = rootGetters['auth/getUser'].Id;
                const response = await axios.put(`customers/Api/Employments/UpdateStatus`, data);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_employmentVerification({ rootGetters }, payload) {
            try {
                let data = payload;
                data.modifiedBy = rootGetters['auth/getUser'].Id;
                const response = await axios.put(`customers/Api/Employments/UpdateIsVerified`, data);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment verification', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async submit_recordsVerification({ rootGetters }, id) {
            try {
                const response = await axios.put(`customers/Api/Employments/SubmitRecordsToVerification`, {
                    customerId: id,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });

                this._vm.$bvToast.toast('Records submitted for verification', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment verification', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        // https://dev-qwihi-api-ocs.mindsetgx.app/customers/Api/Employments/SubmitRecordToVerification/1

        async submit_recordVerification(_, id) {
            try {
                const response = await axios.put(`customers/Api/Employments/SubmitRecordToVerification/${id}`);

                this._vm.$bvToast.toast('Record submitted for verification', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment verification', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
