import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async get_relationsTypes() {
            try {
                const response = await axios.get('customers/Api/ProfileReferences/RelationsTypes');
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting reference types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_reference({ rootGetters }, payload) {
            try {
                const response = await axios.post(`customers/Api/ProfileReferences/Add`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding reference', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_reference({ rootGetters }, payload) {
            try {
                const response = await axios.put(`customers/Api/ProfileReferences/Update`, {
                    ...payload,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating reference', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async remove_reference(_, id) {
            try {
                const response = await axios.delete(`customers/Api/ProfileReferences/Remove/${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error deleting reference', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
