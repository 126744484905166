<template>
    <p class="mb-0">{{ validateText }}</p>
</template>

<script>
import { formatCurrency, formatDate } from '../utils/format';

export default {
    props: {
        text: {
            type: [String, Number],
        },
        isCurrency: {
            type: Boolean,
            default: false,
        },
        isDate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        validateText() {
            const validText = this.text || this.text == '0' ? this.text : '';
            if (this.isCurrency) {
                return formatCurrency(validText);
            }
            if (this.isDate) {
                return formatDate(validText);
            }
            return validText;
        },
    },
};
</script>
