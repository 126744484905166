var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-upload-files","title":"Upload Files","size":"xl","hide-footer":"","centered":""},on:{"close":_vm.clearData}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.uploadFiles($event)}}},[_c('div',{staticClass:"d-flex mb-2"},[_c('b-form-file',{attrs:{"id":"file","placeholder":"Choose files...","drop-placeholder":"Drop files here...","accept":".docx,.png,.jpg,.pdf","multiple":""},on:{"change":_vm.addFiles}})],1),(_vm.files.length > 0)?_c('h5',{staticClass:"mb-2 mt-4"},[_vm._v("Files")]):_vm._e(),_c('b-table',{attrs:{"items":_vm.files,"fields":_vm.tableFields,"responsive":""},scopedSlots:_vm._u([{key:"cell(customerType)",fn:function(ref){
var item = ref.item;
return [_c('b-form-select',{attrs:{"id":"document-types","options":[{ value: null, text: 'Please select an option' } ].concat( _vm.optionsCustomer),"size":"sm","required":""},model:{value:(item.customerType),callback:function ($$v) {_vm.$set(item, "customerType", $$v)},expression:"item.customerType"}})]}},{key:"cell(documentType)",fn:function(ref){
var item = ref.item;
return [_c('b-form-select',{attrs:{"options":[{ id: null, description: 'Please select an option' } ].concat( _vm.documentTypes),"text-field":"description","value-field":"id","size":"sm","required":""},model:{value:(item.documentType),callback:function ($$v) {_vm.$set(item, "documentType", $$v)},expression:"item.documentType"}})]}},{key:"cell(documentDate)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"type":"date","size":"sm","required":""},model:{value:(item.documentDate),callback:function ($$v) {_vm.$set(item, "documentDate", $$v)},expression:"item.documentDate"}})]}},{key:"cell(documentExpirationDate)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"type":"date","size":"sm","required":_vm.isExpDateRequiredForFile(item.documentType)},model:{value:(item.documentExpirationDate),callback:function ($$v) {_vm.$set(item, "documentExpirationDate", $$v)},expression:"item.documentExpirationDate"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.viewFile(item)}}},[_vm._v(" View ")]),_c('b-button',{attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeFile(index)}}},[_vm._v(" Remove ")])]}}])}),_c('div',{staticClass:"d-flex justify-content-end mt-4 btn-grp"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-upload-files')}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"d-flex align-items-center mr-0",attrs:{"variant":"primary","type":"submit","disabled":_vm.files.length == 0 || _vm.loadingSave}},[(_vm.loadingSave)?_c('b-spinner',{attrs:{"small":""}}):[_c('feather',{staticClass:"feather-sm",attrs:{"type":"upload"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Upload")])]],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }