<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
            <div>
                <Header />
                <hr class="m-0" />
            </div>
            <h4>Loans</h4>
            <b-row>
                <b-col cols="12">
                    <b-table
                        small
                        show-empty
                        :filter="filter"
                        striped
                        head-variant="light"
                        :items="paginatedLoans"
                        hover
                        :fields="[
                            { key: 'id', label: 'Loan ID' },
                            { key: 'loanName', label: 'Loan Type' },
                            { key: 'loanAmount', label: 'Loan Amount' },
                            { key: 'creationDate', label: 'Creation Date' },
                            { key: 'approvedDate', label: 'Approved Date' },
                            { key: 'applicantType', label: 'Applied as' },
                            { key: 'accountState', label: 'Status' },
                            { key: 'action', label: 'Action' },
                        ]"
                    >
                        <template #cell(loanAmount)="data"> AWG {{ data.item.loanAmount }} </template>
                        <template #cell(creationDate)="data">
                            {{ formatDate(data.item.creationDate) }}
                        </template>
                        <template #cell(approvedDate)="data">
                            {{ formatDate(data.item.approvedDate) }}
                        </template>
                        <template #cell(applicantType)="data">
                            <b-badge variant="success" class="text-capitalize mr-1">
                                {{ data.item.applicantType }}
                            </b-badge>
                        </template>
                        <template #cell(accountState)="data">
                            <b-badge :variant="getStateVariant(data.item.accountState)" class="text-capitalize mr-1">
                                {{ data.item.accountState }}
                            </b-badge>
                            <b-badge
                                v-if="data.item.accountSubState"
                                :variant="getStateVariant(data.item.accountSubState)"
                                class="text-capitalize"
                            >
                                {{ data.item.accountSubState }}
                            </b-badge>
                        </template>
                        <template #cell(action)="data">
                            <b-button size="sm" variant="primary" @click="handleView(data.item)">View Detail</b-button>
                        </template>
                    </b-table>

                    <div class="pt-2 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="loans.length"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
import Header from '@/components/Header.vue';
import * as dayjs from 'dayjs';
import { mapGetters } from 'vuex';
export default {
    name: 'CustomerLoanHistory',
    components: {
        Header,
    },
    data() {
        return {
            loans: [],
            perPage: 10,
            currentPage: 1,
            pageOptions: [5, 10, 15, 20],
            filter: null,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
        }),
        paginatedLoans() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.loans.slice(start, end);
        },
    },
    methods: {
        async fetchLoans() {
            if (!this.customer.additionalDetails?.nationalID) {
                return;
            }
            this.loading = true;

            // const response = await this.$store.dispatch('mambu/get_loansByEncodedKey', this.customer.encodedKey);
            console.log('this.customer.nationalID', this.customer);

            const response = await this.$store.dispatch(
                'mambu/get_loansHistoryByCustomerNationalID',
                this.customer.additionalDetails.nationalID,
            );
            this.loans = response?.data?.dataResult ?? [];
            this.loading = false;
        },
        formatDate(date) {
            if (!date) return;
            return dayjs(date).format('YYYY-MM-DD');
        },
        handleView(item) {
            this.$router.push(`/loans/all/${item.id}`);
        },
        getStateVariant(state) {
            switch (state) {
                case 'ACTIVE':
                    return 'success';
                case 'APPROVED':
                    return 'primary';
                case 'PENDING_APPROVAL':
                    return 'warning';
                case 'ACTIVE_IN_ARREARS':
                    return 'danger';
                case 'CLOSED':
                    return 'dark';
                case 'PARTIALLY_DISBURSED':
                    return 'warning';
                case 'LOCKED':
                    return 'dark';
                case 'WRITTEN_OFF':
                    return 'dark';
                default:
                    return 'info';
            }
        },
    },
    created() {
        this.fetchLoans();
    },
};
</script>
