<template>
    <b-overlay :show="loading" rounded="sm">
        <main class="my-4 bg-light p-4">
            <div class="row mb-4" v-if="mambuLoan">
                <div class="col-3">
                    <b-card class="p-2 text-center">
                        Total balance
                        <h3 class="text-primary">{{ $formatCurrency(mambuLoan.balances.principalBalance) }}</h3>
                    </b-card>
                </div>
                <div class="col-3">
                    <b-card class="p-2 text-center">
                        Total Due
                        <h3 class="text-primary">{{ $formatCurrency(calculateBalanceDue) }}</h3>
                    </b-card>
                </div>
                <div class="col-3">
                    <b-card class="p-2 text-center">
                        Days in arrears
                        <h3 class="text-primary">{{ mambuLoan.daysInArrears || '0' }}</h3>
                    </b-card>
                </div>
                <div class="col-3">
                    <b-card class="p-2 text-center">
                        Days late
                        <h3 class="text-primary">{{ mambuLoan.daysLate || '0' }}</h3>
                    </b-card>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-12 col-lg-8">
                    <b-card v-if="mambuLoan" class="mb-4 h-100">
                        <div class="d-flex justify-content-between align-items-center">
                            <b-card-title>Loan Information</b-card-title>
                            <div class="text-muted d-flex align-items-center">
                                <b-btn
                                    v-if="application"
                                    variant="primary"
                                    :to="`/applications/list/${application.id}`"
                                >
                                    View Detail
                                </b-btn>
                                <!-- {{ mambuLoan.accountState }}
                                <feather type="info" class="feather-sm text-success ml-2"></feather> -->
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Account ID</label>
                                <p class="mb-1 col-6">{{ mambuLoan.id }}</p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">National ID</label>
                                <p class="mb-1 col-6">{{ customer ? customer.id : '' }}</p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">First Name</label>
                                <p class="mb-1 col-6">{{ customer ? customer.firstName : '' }}</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Middle Name</label>
                                <p class="mb-1 col-6">{{ customer ? customer.middleName : '' }}</p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Last Name</label>
                                <p class="mb-1 col-6">{{ customer ? customer.lastName : '' }}</p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Assigned to Branch</label>
                                <p class="mb-1 col-6">
                                    {{ mambuLoan.assignedBranchName ? mambuLoan.assignedBranchName : 'CLIENTS' }}
                                </p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Account state</label>
                                <b-badge :variant="getStateVariant(mambuLoan.accountState)" class="text-capitalize mb-1 ml-2">
                                        {{ mambuLoan.accountState }}
                                </b-badge>
                            </div>

                            <div v-if="mambuLoan.accountSubState" class="row">
                                <label class="fw-medium mb-0 col-6">Account substate</label>
                                <b-badge 
                                    :variant="getStateVariant(mambuLoan.accountSubState)" 
                                    class="text-capitalize mb-1 ml-2">
                                        {{ mambuLoan.accountSubState }}
                                </b-badge>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Days in Arrears</label>
                                <p class="mb-1 col-6">
                                    {{ mambuLoan.daysInArrears > 0 ? mambuLoan.daysInArrears : 0 }}
                                </p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Currency</label>
                                <p class="mb-1 col-6">{{ mambuLoan.currency.currencyCode }}</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Activation Date</label>
                                <p class="mb-1 col-6">{{ $formatDate(mambuLoan.creationDate) }}</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Product</label>
                                <p class="mb-1 col-6">{{ mambuLoan.loanName }}</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Loan Amount</label>
                                <p class="mb-1 col-6">{{ mambuLoan.loanAmount }}</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Interest Rate</label>
                                <p class="mb-1 col-6">{{ mambuLoan.interestSettings.interestRate }}%</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Penalty Rate</label>
                                <p class="mb-1 col-6">{{ mambuLoan.penaltySettings.penaltyRate }}%</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Interest Calculation Method</label>
                                <p class="mb-1 col-6">{{ mambuLoan.interestSettings.interestCalculationMethod }}</p>
                            </div>
                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Accrued Interest Posting Frequency</label>
                                <p class="mb-1 col-6">{{ mambuLoan.interestSettings.interestChargeFrequency }}</p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Accrued Interest</label>
                                <p class="mb-1 col-6">{{ $formatCurrency(mambuLoan.accruedInterest) }}</p>
                            </div>

                            <div class="row">
                                <label class="fw-medium mb-0 col-6">Penalty Calculation Method</label>
                                <p class="mb-1 col-6">{{ mambuLoan.penaltySettings.loanPenaltyCalculationMethod }}</p>
                            </div>
                        </div>
                    </b-card>
                </div>

                <div class="col-12 col-lg-4">
                    <b-card v-if="mambuLoan" class="mb-3">
                        <b-card-title>Timeline</b-card-title>
                        <div class="timeline block mb-6">
                            <div class="tl-item">
                                <div class="tl-dot border-primary"></div>
                                <div class="tl-content">
                                    <h6>Creation Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 120px">
                                        {{ $formatDate(mambuLoan.creationDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item">
                                <div class="tl-dot border-primary"></div>
                                <div class="tl-content">
                                    <h6>Approved Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 120px">
                                        {{ $formatDate(mambuLoan.approvedDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item">
                                <div class="tl-dot border-primary"></div>
                                <div class="tl-content">
                                    <h6>Disbursement Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 125px">
                                        {{ $formatDate(mambuLoan.disbursementDetails.disbursementDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item" v-if="mambuLoan.closedDate">
                                <div class="tl-dot border-danger"></div>
                                <div class="tl-content">
                                    <h6>Closed Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 120px">
                                        {{ $formatDate(mambuLoan.closedDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item">
                                <div class="tl-content"></div>
                            </div>
                        </div>
                    </b-card>
                    <b-card v-if="mambuLoan && mambuLoan.disbursementDetails">
                        <b-card-title> Disbursement Information </b-card-title>
                        <div class="row">
                            <label class="fw-medium mb-0 col-6">Amount</label>
                            <p class="mb-1 col-6">{{ $formatCurrency(mambuLoan.loanAmount) }}</p>
                        </div>
                        <div class="row">
                            <label class="fw-medium mb-0 col-6">Expected Date</label>
                            <p class="mb-1 col-6">
                                {{ $formatDate(mambuLoan.disbursementDetails.expectedDisbursementDate) }}
                            </p>
                        </div>
                        <div class="row">
                            <label class="fw-medium mb-0 col-6">Disbursement Date</label>
                            <p class="mb-1 col-6">{{ $formatDate(mambuLoan.disbursementDetails.disbursementDate) }}</p>
                        </div>

                        <!-- <div class="mb-3">
                            <h6 class="mb-0">Amount</h6>
                            <ItemText :text="mambuLoan.loanAmount" isCurrency />
                        </div>
                        <div class="mb-3">
                            <h6 class="mb-0">Expected date</h6>
                            <ItemText :text="$formatDate(mambuLoan.disbursementDetails.expectedDisbursementDat)" />
                        </div>
                        <div>
                            <h6 class="mb-0">Disbursement Date</h6>

                            <ItemText :text="$formatDate(mambuLoan.disbursementDetails.disbursementDate)" />
                        </div> -->
                    </b-card>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-4"></div>
                <div :class="'col-12'">
                    <b-card>
                        <b-card-title>Schedule</b-card-title>

                        <b-table
                            style="min-height: 200px; max-height: 600px; overflow-y: scroll"
                            show-empty
                            responsive
                            sticky-header
                            class="mb-0 mt-2"
                            :filter="null"
                            :items="schedules"
                            :fields="fields"
                            hover
                            foot-clone
                            borderless
                            striped
                        >
                            <template #cell(dueDate)="data"> {{ $formatDate(data.item.dueDate) }}</template>
                            <template #cell(principalPaid)="data">
                                {{ $formatCurrency(data.item.principal.amount.paid) }}
                            </template>
                            <template #cell(interestPaid)="data">
                                {{ $formatCurrency(data.item.interest.amount.paid) }}
                            </template>
                            <template #cell(feesPaid)="data">
                                {{ $formatCurrency(data.item.fee.amount.paid) }}
                            </template>
                            <template #cell(totalPaid)="data">
                                {{
                                    $formatCurrency(
                                        data.item.principal.amount.paid +
                                            data.item.interest.amount.paid +
                                            data.item.fee.amount.paid,
                                    )
                                }}
                            </template>
                            <template #cell(lastPaidDate)="data">
                                {{ $formatDate(data.item.lastPaidDate) }}
                            </template>

                            <template #cell(principalDue)="data">
                                {{ $formatCurrency(data.item.principal.amount.due) }}
                            </template>
                            <template #cell(interestDue)="data">
                                {{ $formatCurrency(data.item.interest.amount.due) }}
                            </template>
                            <template #cell(feesDue)="data">
                                {{ $formatCurrency(data.item.fee.amount.due) }}
                            </template>
                            <template #cell(totalDue)="data">
                                {{
                                    $formatCurrency(
                                        data.item.principal.amount.due +
                                            data.item.interest.amount.due +
                                            data.item.fee.amount.due +
                                            data.item.penalty.amount.due,
                                    )
                                }}
                            </template>
                            <template #cell(state)="data">
                                <b-badge
                                    :variant="
                                        data.item.state === 'PENDING'
                                            ? 'secondary'
                                            : data.item.state === 'PAID'
                                              ? 'success'
                                              : 'danger'
                                    "
                                    class="text-capitalize"
                                >
                                    {{ data.item.state }}
                                </b-badge>
                            </template>
                            <!-- //add footer with totals to each column -->

                            <template v-slot:foot()="data">
                                <!-- { "label": "Due", "column": "dueDate", "field": { "key": "dueDate", "label": "Due" }, "isFoot": true } -->
                                <span>
                                    {{ $formatCurrency(totalsColumns[data.column]) }}
                                </span>
                            </template>
                        </b-table>
                    </b-card>
                </div>
            </div>

            <b-card>
                <h4 class="text-center mb-4">Actions</h4>
                <div
                    :style="{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${hasLoanApplication ? 5 : 4}, 1fr)`,
                        gap: '1rem',
                        padding: '1rem',
                    }"
                >
                    <div class="d-flex justify-content-center border-right">
                        <b-button
                            variant="primary"
                            class="d-flex align-items-center justify-content-center"
                            @click="updateStateMambu"
                            :disabled="!isPending"
                        >
                            <b-spinner small v-if="loadingState"></b-spinner>
                            <feather v-else type="user-check" class="feather-sm mr-2"></feather>
                            Approve Loan
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-center border-right">
                        <b-button
                            :variant="'primary'"
                            class="d-flex align-items-center justify-content-center"
                            :disabled="!isApproved"
                            v-b-modal.modal-transferred-funds
                        >
                            <feather type="dollar-sign" class="feather-sm mr-2"></feather>
                            Disburse Funds
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-center border-right">
                        <b-button
                            class="d-flex align-items-center justify-content-center"
                            variant="primary"
                            v-b-modal.modal-reschedule
                            :disabled="!isActive || hasLoanApplication || calculateBalanceDue <= 0"
                        >
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            <feather v-else type="edit" class="feather-sm mr-2"></feather>
                            Reschedule
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-center">
                        <b-button
                            class="d-flex align-items-center justify-content-center"
                            variant="primary"
                            v-b-modal.modal-refinance
                            :disabled="!isActive || hasLoanApplication || calculateBalanceDue <= 0"
                        >
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            <feather v-else type="credit-card" class="feather-sm mr-2"></feather>
                            Refinance
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-center" v-if="hasLoanApplication && currentLoanApplication">
                        <b-button
                            class="d-flex align-items-center justify-content-center"
                            variant="primary"
                            @click="goToActiveLoan"
                        >
                            <feather type="user" class="feather-sm mr-2"></feather>
                            View active application
                        </b-button>
                    </div>
                </div>
            </b-card>

            <b-modal id="modal-refinance" hide-footer centered title="Refinance" size="md">
                <form @submit.prevent="handleAction('refinance')" v-if="mambuLoan">
                    <div class="row mx-0">
                        <label class="col-4">Balance Due</label>
                        <p class="col-8">{{ $formatCurrency(calculateBalanceDue) }}</p>
                    </div>

                    <div class="row mb-3 mx-0">
                        <label for="LoanAmount" class="col-4" style="text-wrap: nowrap">Fresh Founds </label>
                        <b-input
                            type="number"
                            class="col-8"
                            id="LoanAmount"
                            name="LoanAmount"
                            v-model="amount"
                            v-bind:min="minAmount"
                            v-bind:max="maxAmount"
                            step="0.01"
                            placeholder=""
                            required
                        />

                        {{ minAmount }} - {{ maxAmount }}
                    </div>

                    <div class="row mx-0">
                        <label class="col-4">Total Amount</label>
                        <p class="col-8">
                            {{ $formatCurrency(calculateTotalAmount) }}
                        </p>
                    </div>
                    <div class="row mx-0">
                        <label for="DDLPeriod" class="col-4" style="text-wrap: nowrap">Period in Months</label>
                        <b-form-select v-model="monthSelected" id="DDLPeriod" name="DDLPeriod" required class="col-8">
                            <b-form-select-option v-for="Month in monthsMambu" :key="Month" :value="Month" required>{{
                                Month
                            }}</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div>
                        <label for="noteRefinance" style="font-weight: 700">Note</label>
                        <b-form-textarea
                            id="noteRefinance"
                            v-model="note"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                            required
                        ></b-form-textarea>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-refinance')"
                            >Cancel</b-button
                        >
                        <b-button variant="primary" type="submit">
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            Refinance
                        </b-button>
                    </div>
                </form>
            </b-modal>

            <b-modal id="modal-reschedule" hide-footer centered title="Reschedule" size="sm">
                <form @submit.prevent="handleAction('reschedule')">
                    <div>
                        <div>
                            <label for="DDLPeriod" style="font-weight: 700">Period in Months</label>
                            <b-form-select
                                v-model="monthSelected"
                                class="mb-3"
                                id="DDLPeriod"
                                name="DDLPeriod"
                                required
                            >
                                <b-form-select-option
                                    v-for="Month in monthsMambu"
                                    :key="Month"
                                    :value="Month"
                                    required
                                    >{{ Month }}</b-form-select-option
                                >
                            </b-form-select>
                        </div>
                        <div>
                            <label for="noteRefinance" style="font-weight: 700">Note</label>
                            <b-form-textarea
                                id="noteRefinance"
                                v-model="note"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                                required
                            ></b-form-textarea>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-reschedule')"
                            >Cancel</b-button
                        >
                        <b-button variant="primary" type="submit">
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            Reschedule
                        </b-button>
                    </div>
                </form>
            </b-modal>

            <b-modal id="modal-transferred-funds" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to transfer funds? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-transferred-funds')"
                        >No</b-button
                    >
                    <b-button
                        variant="primary"
                        @click="updateStatusFunds('TransferredFunds', !process.transferredFunds)"
                    >
                        <b-spinner small v-if="loadingFunds"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>
        </main>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { MARITAL_STATUS } from '@/constants/main';

export default {
    name: 'CreatedLoanDetail',

    data() {
        return {
            loan: null,
            createdLoan: null,
            schedules: [],
            disbursement: null,
            loading: true,
            loadingRefinance: false,
            loadingReschedule: false,
            loadingAction: false,
            fields: [
                { key: 'number', label: 'Number' },
                { key: 'dueDate', label: 'Due' },

                // { key: 'principalExpected', label: 'Principal Expected' },
                // { key: 'interestExpected', label: 'Interest Expected' },
                // { key: 'feesExpected', label: 'Fees Expected' },
                // { key: 'totalExpected', label: 'Total Expected' },

                { key: 'principalPaid', label: 'Principal Paid' },
                { key: 'interestPaid', label: 'Interest Paid' },
                { key: 'feesPaid', label: 'Fees Paid' },
                { key: 'totalPaid', label: 'Total Paid' },
                { key: 'lastPaidDate', label: 'Paid Date' },

                { key: 'principalDue', label: 'Principal Due' },
                { key: 'interestDue', label: 'Interest Due' },
                { key: 'feesDue', label: 'Fees Due' },
                { key: 'totalDue', label: 'Total Due' },

                { key: 'state', label: 'State' },
            ],
            amount: null,
            monthSelected: null,
            monthsArray: [],
            monthsMambu: [18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84],
            monthsRescheduleArray: [],
            minAmount: null,
            maxAmount: null,
            debounceTimer: null,
            note: null,
            mambuLoan: null,
            loadingState: false,
            loadingFunds: false,
            customer: null,
            application: null,
            loans: [],
            hasLoanApplication: false,
            currentLoanApplication: null,
        };
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            process: 'customer/productRequest/getProcess',
        }),
        isPending() {
            return this.mambuLoan?.accountState === 'PENDING_APPROVAL';
        },
        isApproved() {
            return (
                this.mambuLoan?.accountState === 'APPROVED' ||
                (this.mambuLoan?.accountState === 'ACTIVE' && this.mambuLoan?.accountSubState === 'PARTIALLY_DISBURSED')
            );
        },
        isActive() {
            return this.mambuLoan?.accountState === 'ACTIVE' || this.mambuLoan?.accountState === 'ACTIVE_IN_ARREARS';
        },
        maritalStatus() {
            return MARITAL_STATUS.find((el) => el.id === this.customer?.additionalDetails?.maritalStatusId);
        },
        calculateTotalAmount() {
            return parseFloat(this.amount || 0) + parseFloat(this.calculateBalanceDue);
        },
        calculateBalanceDue() {
            return (
                parseFloat(this.mambuLoan?.balances?.principalBalance || 0) +
                parseFloat(this.mambuLoan?.balances?.interestFromArrearsBalance || 0) +
                parseFloat(this.mambuLoan?.balances?.interestBalance || 0) +
                parseFloat(this.mambuLoan?.accruedInterest || 0) +
                parseFloat(this.mambuLoan?.balances?.feesBalance || 0)
            );
        },

        totalsColumns() {
            return {
                principalExpected: this.schedules.reduce((acc, item) => acc + item.principal.amount.expected, 0),
                interestExpected: this.schedules.reduce((acc, item) => acc + item.interest.amount.expected, 0),
                feesExpected: this.schedules.reduce((acc, item) => acc + item.fee.amount.expected, 0),
                totalExpected: this.schedules.reduce(
                    (acc, item) =>
                        acc + item.principal.amount.expected + item.interest.amount.expected + item.fee.amount.expected,
                    0,
                ),

                principalPaid: this.schedules.reduce((acc, item) => acc + item.principal.amount.paid, 0),
                interestPaid: this.schedules.reduce((acc, item) => acc + item.interest.amount.paid, 0),
                feesPaid: this.schedules.reduce((acc, item) => acc + item.fee.amount.paid, 0),
                totalPaid: this.schedules.reduce(
                    (acc, item) => acc + item.principal.amount.paid + item.interest.amount.paid + item.fee.amount.paid,
                    0,
                ),

                principalDue: this.schedules.reduce((acc, item) => acc + item.principal.amount.due, 0),
                interestDue: this.schedules.reduce((acc, item) => acc + item.interest.amount.due, 0),
                feesDue: this.schedules.reduce((acc, item) => acc + item.fee.amount.due, 0),
                totalDue: this.schedules.reduce(
                    (acc, item) => acc + item.principal.amount.due + item.interest.amount.due + item.fee.amount.due,
                    0,
                ),
            };
        },
    },

    methods: {
        goToActiveLoan() {
            const customerId = this.currentLoanApplication?.applicantInfo.id;
            const loanApplicationId = this.currentLoanApplication?.id;

            if (customerId && loanApplicationId) {
                this.$router.push(`/applications/list/${loanApplicationId}`);
            }
        },
        async getData() {
            try {
                this.loading = true;
                await this.getMambuLoan();
                await this.getCustomerData();
                await this.getLoanApplicationByLoanId();
                await this.getLoanApplicationBySourceLoanId();
                await this.fetchMonths();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },

        async getLoanApplicationBySourceLoanId() {
            const response = await this.$store.dispatch(
                'loan/applications/get_loanApplicationBySourceLoanId',
                this.$route.params.loan,
            );

            this.hasLoanApplication = response?.statusResult;
            this.currentLoanApplication = response?.dataResult;
        },

        async getMambuLoan() {
            const response = await this.$store.dispatch('mambu/get_mambuLoanByLoanId', this.$route.params.loan);
            this.mambuLoan = response.data.dataResult?.payload;
            this.amount = this.mambuLoan.loanAmount;

            const scheduleData = await this.$store.dispatch('mambu/get_mambuSchedule', this.$route.params.loan);
            this.schedules = scheduleData.data.dataResult.payload.installments;
        },

        async getCustomerData() {
            const response = await this.$store.dispatch(
                'mambu/get_customerByEncodedKey',
                this.mambuLoan.accountHolderKey,
            );
            this.customer = response?.payload;
        },

        async getLoanApplicationByLoanId() {
            const response = await this.$store.dispatch(
                'loan/applications/get_loanApplicationByLoanId',
                this.$route.params.loan,
            );
            this.application = response?.dataResult;
        },

        async fetchMonths() {
            const loanTypeId = this.application?.loanTypeId ?? 1;

            const response = await this.$store.dispatch('mambu/get_mambuProducts', {
                loanTypeId,
                repaymentMethodId: 1,
            });

            this.minAmount = response.payload.loanAmountSettings.loanAmount.minValue;
            this.maxAmount = response.payload.loanAmountSettings.loanAmount.maxValue;

            this.monthsArray = [];

            const minValue = response.payload.scheduleSettings.numInstallments.minValue;
            const maxValue = response.payload.scheduleSettings.numInstallments.maxValue;

            for (let i = minValue; i <= maxValue; i += 6) {
                this.monthsArray.push(i);
            }
            this.monthSelected = this.monthsArray[0];
        },

        async updateStatusFunds(service, status) {
            const res = await this.$store.dispatch('mambu/make_disbursement', this.$route.params.loan);
            if (res) {
                if (this.status != null && this.serviceSelected != null) this.loadingFunds = true;
                await this.$store.dispatch(`customer/productRequest/update_status${service}`, {
                    id: this.$route.params.customer,
                    status: status,
                });
                await this.getData();
                this.loadingFunds = false;
                this.$bvModal.hide('modal-transferred-funds');
            }
        },

        getStateVariant(state) {
            switch (state) {
                case 'ACTIVE':
                    return 'success';
                case 'APPROVED':
                    return 'primary';
                case 'PENDING_APPROVAL':
                    return 'warning';
                case 'ACTIVE_IN_ARREARS':
                    return 'danger';
                case 'CLOSED':
                    return 'dark';
                case 'PARTIALLY_DISBURSED':
                    return 'warning';
                case 'LOCKED':
                    return 'dark';
                case 'WRITTEN_OFF':
                    return 'dark';            
                default:
                    return 'info';
            }
        },

        showToast(message) {
            this.$bvToast.toast(message, {
                title: 'Attention!',
                variant: 'warning',
                solid: true,
            });
        },
        async updateStateMambu() {
            this.loadingState = true;
            await this.$store.dispatch('mambu/update_mambuState', this.$route.params.loan);
            await this.getData();
            this.loadingState = false;
        },

        async handleAction(actionType) {
            this.loadingAction = true;

            const customerId = await this.$store.dispatch('mambu/validate_ifCustomerExistOrCreate', {
                id: this.mambuLoan.accountHolderKey,
                showWarning: true,
            });

            if (!customerId) return;

            let payload = {
                loanApplicationId: this.application?.id ?? 0,
                loanId: this.$route.params.loan,
                periodInMonths: this.monthSelected,
                productTypeKey: this.mambuLoan.productTypeKey,
                customerId: customerId,
                notes: this.note,
                loanAmount: this.calculateTotalAmount,
            };

            if (actionType === 'refinance') {
                this.loadingRefinance = true;

                const response = await this.$store.dispatch('loan/applications/refinance_loanApplication', payload);
                if (response.dataResult) window.open(`/applications/list/${response.dataResult}`, '_blank');
            } else {
                this.loadingReschedule = true;
                payload = {
                    ...payload,
                    principalBalance: this.mambuLoan.balances.principalBalance,
                };
                const response = await this.$store.dispatch('loan/applications/reschedule_loanApplication', payload);
                if (response.dataResult) window.open(`/applications/list/${response.dataResult}`, '_blank');
            }

            await this.getData();
            this.loadingAction = false;

            this.$bvModal.hide('modal-refinance');
            this.$bvModal.hide('modal-reschedule');
            this.clearData();
        },

        clearData() {
            this.amount = null;
            this.monthSelected = null;
            this.note = null;
        },
    },

    async created() {
        await this.getData();
    },
};
</script>

<style>
.tl-item .avatar {
    z-index: 2;
}

.circle {
    border-radius: 500px;
}

.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.timeline {
    position: relative;
    padding: 0;
    margin: 0;
}

.p-4 {
    padding: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
}

.tl-item > * {
    padding: 10px;
}

.tl-item .avatar {
    z-index: 2;
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

.tl-content p:last-child {
    margin-bottom: 0;
}

.tl-date {
    font-size: 0.85em;
    margin-top: 2px;
    min-width: 100px;
    max-width: 100px;
}

.avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.b-warning {
    border-color: #f4c414 !important;
}

.b-primary {
    border-color: #448bff !important;
}

.b-danger {
    border-color: #f54394 !important;
}
</style>
