<template>
    <Select
        :items="options"
        @option-selected="handleOption"
        @search-input="handleSearchInput"
        :defaultOption="defaultOption"
    />
</template>
<script>
import Select from './Select.vue';
import { debounce } from 'lodash';
export default {
    components: {
        Select,
    },
    props: {
        store: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        defaultOption: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            data: [],
            options: [],
            query: '',
            debouncedSearch: null,
        };
    },
    methods: {
        async getData() {
            const response = await this.$store.dispatch(this.store, {
                skip: 1,
                take: 20,
                status: true,
                query: this.query,
            });

            this.data = response?.dataResult ?? [];
            this.options = this.data.map((el) => {
                return {
                    prependText: el.nationalID ?? 'NA',
                    text: `${el[this.text]} ${el.middleName} ${el.lastName} ${el.blacklisted ? '(Blacklisted)' : ''}  ${el.isDeceased ? '(Deceased)' : ''} ${el.pep ? '(PEP)' : ''}`,
                    value: el[this.value],
                };
            });
        },
        handleOption(item) {
            if (!item) {
                this.$emit('option-selected', null);
                return;
            }
            const selectedOption = this.data.find((el) => el[this.value] == item.value);
            this.$emit('option-selected', selectedOption);
        },
        handleSearchInput(query) {
            this.query = query;
            this.debouncedSearch();
        },
    },
    //watch prop store and clear options & data
    watch: {
        store() {
            this.options = [];
            this.data = [];
            this.$emit('option-selected', null);
        },
    },

    created() {
        this.debouncedSearch = debounce(this.getData, 300);
        if (this.defaultOption) {
            this.options = [
                {
                    prependText: this.defaultOption.nationalID ?? 'NA',
                    text: `${this.defaultOption[this.text]} ${this.defaultOption.middleName} ${this.defaultOption.lastName} ${
                        this.defaultOption.blacklisted ? '(Blacklisted)' : ''
                    } ${this.defaultOption.isDeceased ? '(Deceased)' : ''}  ${this.defaultOption.pep ? '(PEP)' : ''}`,
                    value: this.defaultOption[this.value],
                },
            ];
        }
    },
};
</script>
