<template>
    <b-card no-body class="px-4">
        <template>
            <Header />
            <hr class="my-3" />
        </template>
        <b-form @submit.prevent="save" class="account-form d-flex flex-column" style="row-gap: 12px">
            <fieldset class="">
                <h4 class="mb-2">Loan Information</h4>
                <div class="row" style="row-gap: 12px">
                    <div class="col-4">
                        <label for="DDLTypeLoan" class="mb-1">Loan Type</label>
                        <b-form-select
                            size="sm"
                            v-model="loanType"
                            id="DDLTypeLoan"
                            name="DDLTypeLoan"
                            v-on:change="changeLoanType()"
                            required
                        >
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option value="1">Personal</b-form-select-option>
                            <b-form-select-option value="2">Vehicle</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="col-4">
                        <label for="officer" class="mb-1">Select an officer</label>
                        <b-form-select
                            class="mb-3"
                            id="officer"
                            :options="[{ id: null, userName: 'Please select an option' }, ...officers]"
                            v-model="selectedOfficer"
                            value-field="id"
                            text-field="userName"
                            size="sm"
                        ></b-form-select>
                    </div>
                    <div class="col-4"></div>

                    <div class="col-4">
                        <label for="LoanAmount" class="mb-1">Amount</label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="LoanAmount"
                            name="LoanAmount"
                            step="0.01"
                            v-model="amount"
                            :readonly="loanApplication.serieType == 'AU'"
                            v-bind:min="minAmount"
                            v-bind:max="maxAmount"
                            @blur="debounce()"
                            placeholder=""
                            required
                        />
                        <span class="span" v-if="minAmount && maxAmount">Range: {{ minAmount }} - {{ maxAmount }}</span>
                    </div>

                    <div class="col-4">
                        <label for="DDLPeriod" class="mb-1">Installments</label>

                        <b-form-select
                            size="sm"
                            v-model="monthSelectedOption"
                            id="DDLPeriod"
                            name="DDLPeriod"
                            v-on:change="getMonthlyPayment()"
                            required
                        >
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option v-for="month in monthsArray" :key="month" :value="month">{{
                                month
                            }}</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="col-4">
                        <label for="dynamicInterestRate" class="mb-1"> Dynamic interest rate </label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="dynamicInterestRate"
                            name="dynamicInterestRate"
                            v-model="dynamicInterestRate"
                            placeholder="0.00"
                            readonly
                        />
                    </div>
                    <div class="col-4">
                        <label for="MonthlyPayment" class="mb-1">
                            Monthly Payment
                            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        </label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="MonthlyPayment"
                            name="MonthlyPaCment"
                            v-model="monthlyPayment"
                            placeholder="AWG 0.00"
                            readonly
                        />
                    </div>

                    <div class="col-4">
                        <label for="fee" class="mb-1">
                            Administration fee
                            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        </label>
                        <b-input
                            size="sm"
                            type="number"
                            id="fee"
                            name="expectedAdminFee"
                            v-model="expectedAdminFee"
                            placeholder="AWG 0.00"
                            :readonly="!$validatePermission('/LoanApplications-Edit-Admin-Fee')"
                            v-on:input="debounce()"
                        />
                    </div>

                    <div class="col-4">
                        <label for="fee" class="mb-1"> Total expected amount </label>
                        <b-input
                            size="sm"
                            type="number"
                            class="totalExpectedAmount"
                            id="fee"
                            name="MonthlyPaCment"
                            :value="totalExpectedAmount"
                            placeholder="AWG 0.00"
                            readonly
                        />
                    </div>

                    <div class="col-4">
                        <label for="fee" class="mb-1"> APR </label>
                        <b-input
                            size="sm"
                            type="text"
                            id="fee"
                            v-model="apr"
                            name="apr"
                            placeholder="AWG 0.00"
                            :readonly="!$validatePermission('/LoanApplications-Edit-APR')"
                            v-on:input="debounce()"
                        />
                    </div>
                </div>
            </fieldset>

            <fieldset class="">
                <div class="row" style="row-gap: 12px">
                    <div class="col-4">
                        <label for="repayment" class="mb-1"> Repayment Method </label>
                        <!-- select -->
                        <b-form-select v-model="repaymentMethod" size="sm" id="repayment" name="repayment" required>
                            <b-form-select-option value="1">CASH</b-form-select-option>
                            <b-form-select-option value="2" v-if="loanType == 1">POB</b-form-select-option>
                        </b-form-select>
                    </div>

                    <b-col sm="4" class="">
                        <label class="fw-medium mb-1" for="comp4">Purpose</label>
                        <b-form-group class="mb-0">
                            <b-form-select
                                size="sm"
                                required
                                :options="[{ id: null, description: 'Please select an option' }, ...purposes]"
                                text-field="description"
                                value-field="id"
                                v-model="purpose"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" class="">
                        <label class="fw-medium mb-1" for="">Purpose Details</label>
                        <b-input
                            size="sm"
                            type="text"
                            id="purposeDetails"
                            name="purposeDetails"
                            v-model="purposeDetails"
                        />
                    </b-col>
                </div>
            </fieldset>

            <fieldset class="">
                <div class="row">
                    <div class="col-4 d-none">
                        <label class="fw-medium mb-1" for="cus4">Reason Type</label>
                        <b-form-radio-group
                            v-model="reasonType"
                            :options="customersTypes"
                            class="d-flex"
                            value-field="id"
                            text-field="name"
                            required
                        ></b-form-radio-group>
                    </div>
                    <div v-if="reasonType == 2" class="col-4">
                        <label for="fee" class="mb-1"> Business Name </label>
                        <b-input
                            size="sm"
                            v-model="businessName"
                            type="text"
                            class="WhitePlaceHolder"
                            id="fee"
                            name="MonthlyPaCment"
                            placeholder="Business"
                        />
                    </div>
                </div>
            </fieldset>

            <fieldset class="">
                <h4 class="mb-2">Customer Information</h4>
                <div class="row">
                    <div class="col-4">
                        <div class="mb-2">
                            <label>Search an applicant</label>
                            <SearchSelect
                                store="customer/get_allCustomers"
                                text="firstName"
                                value="id"
                                :defaultOption="applicant"
                                @option-selected="handleOption"
                            />
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="mb-2">
                            <label>Search a co-applicant</label>
                            <SearchSelect
                                store="customer/get_allCustomers"
                                text="firstName"
                                value="id"
                                :defaultOption="coApplicant"
                                @option-selected="handleOptioCoApplicant"
                            />
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mb-2">
                            <label>Search an guarantor</label>
                            <SearchSelect
                                store="customer/get_allCustomers"
                                text="firstName"
                                value="id"
                                :defaultOption="guarantor"
                                @option-selected="handleOptionGuarantor"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>

            <div
                v-if="hasPermissionToApprove && loanApplication.loanProcessStatusLog.currentStatusCode != '220'"
                class="d-flex justify-content-end"
            >
                <b-button type="submit" id="BtnSendForm" variant="primary" class="px-4" :disabled="loadingData">
                    <b-spinner small v-if="loadingData"></b-spinner>
                    <span v-else>Save</span>
                </b-button>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchSelect from '@/components/SearchSelect.vue';
import Header from '@/components/Header.vue';
import { validatePermission } from '../../utils/auth';

export default {
    name: 'ApplicationMain',
    components: {
        SearchSelect,
        Header,
    },
    data() {
        return {
            loanType: null,
            amount: null,
            totalExpectedAmount: null,
            monthsArray: [],
            monthSelectedOption: null,
            minAmount: null,
            maxAmount: null,
            monthlyPayment: 0,
            loading: false,
            loadingData: false,
            purpose: null,
            customers: [],
            applicant: null,
            coApplicant: null,
            guarantor: null,
            expectedAdminFee: 0,
            purposes: [],
            reasonType: null,
            businessName: null,
            repaymentMethod: null,
            purposeDetails: null,
            purposeDescription: null,
            apr: null,
            officers: [],
            selectedOfficer: null,
            dynamicInterestRate: 0,
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            customersTypes: 'general/customersTypes/getcustomersTypes',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermissionToApprove() {
            const status = this.loanApplication?.loanProcessStatusLog?.currentStatusCode;
            if (status == '130' || status == '140') {
                return validatePermission('/LoanApplications-Update-Approved');
            }
            return true;
        },
    },
    methods: {
        async save() {
            const payload = {
                id: this.loanApplication.id,
                loanTypeId: this.loanType,
                periodInMonths: this.monthSelectedOption,
                loanAmount: this.amount,
                apr: this.apr,
                expectedAdminFee: this.expectedAdminFee,
                repaymentMethod: this.repaymentMethod,
                purposeId: this.purpose,
                purposeDetails: this.purposeDetails,
                purposeDescription: this.purposeDescription,
                customerId: this.applicant?.id,
                coApplicantId: this.coApplicant?.id,
                guarantorId: this.guarantor?.id,
                modifiedBy: this.user.id,
                agentId: this.selectedOfficer,
                origin: 1,
                isActive: true,
            };

            this.loadingData = true;
            await this.$store.dispatch('loan/applications/update_loanApplication', payload);
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.params.id);
            this.loadingData = false;
        },

        async changeLoanType() {
            if (this.loanType == 2) {
                this.purpose = 14;
                this.repaymentMethod = 1;
            }
            await this.getMonths();
        },

        // async getMinAndMaxAmount() {
        //     const response = await this.$store.dispatch('loan/applications/get_loanRange', this.loanType);
        //     this.minAmount = response.minAmount;
        //     this.maxAmount = response.maxAmount;

        //     if (this.amount < this.minAmount) {
        //         this.amount = this.minAmount;
        //     } else if (this.amount > this.maxAmount) {
        //         this.amount = this.maxAmount;
        //     }

        //     await this.getMonths();
        // },

        async getMonths() {
            // const payload = {
            //     amount: this.amount,
            //     loanType: this.loanType,
            // };
            // const response = await this.$store.dispatch('loan/applications/get_monthsByLoanType', payload);
            const response = await this.$store.dispatch('mambu/get_mambuProducts', {
                loanTypeId: this.loanType,
                repaymentMethodId: this.repaymentMethod,
            });

            this.minAmount = response.payload.loanAmountSettings.loanAmount.minValue;
            this.maxAmount = response.payload.loanAmountSettings.loanAmount.maxValue;

            this.monthsArray = [];

            const minValue = response.payload.scheduleSettings.numInstallments.minValue;
            const maxValue = response.payload.scheduleSettings.numInstallments.maxValue;

            for (let i = minValue; i <= maxValue; i += 6) {
                this.monthsArray.push(i);
            }

            this.getMonthlyPayment();
        },
        async getMonthlyPayment() {
            this.loading = true;
            const payload = {
                amount: this.amount,
                months: this.monthSelectedOption,
                apr: this.apr,
                fee: this.expectedAdminFee,
            };
            const response = await this.$store.dispatch('loan/applications/get_monthlyPayment', payload);
            this.monthlyPayment = response.amount?.toFixed(2);
            this.totalExpectedAmount = (this.monthlyPayment * this.monthSelectedOption).toFixed(2);
            this.apr = response.apr?.toFixed(2);
            this.dynamicInterestRate = response.dynamicInterestRate?.toFixed(2);
            this.loading = false;
        },
        debounce() {
            if (this.loanType == null) {
                this.$bvToast.toast('Please select a loan type.', {
                    title: 'Please!',
                    variant: 'warning',
                    solid: true,
                });
                return;
            }

            this.loading = true;
            clearTimeout(this.inputTimer);

            const validateAmount = () => {
                let loanTypeDescription = this.loanType == 1 ? 'Personal' : 'Vehicle';

                if (this.amount < this.minAmount) {
                    this.$bvToast.toast(`Our ${loanTypeDescription} Loans start at AWG ${this.minAmount}.`, {
                        title: 'Please!',
                        variant: 'warning',
                        solid: true,
                    });
                    this.amount = this.minAmount;
                } else if (this.amount > this.maxAmount) {
                    this.$bvToast.toast(
                        `You have exceeded the maximum loan amount. Our ${loanTypeDescription} Loans go up to ${this.maxAmount}.`,
                        {
                            title: 'Please!',
                            variant: 'warning',
                            solid: true,
                        },
                    );
                    this.amount = this.maxAmount;
                }
            };

            this.inputTimer = setTimeout(async () => {
                if (this.amount < this.minAmount || this.amount > this.maxAmount) {
                    validateAmount();
                }
                this.getMonthlyPayment();
            }, 1500);
        },

        handleOption(item) {
            this.applicant = item;
        },
        handleOptioCoApplicant(item) {
            this.coApplicant = item;
        },
        handleOptionGuarantor(item) {
            this.guarantor = item;
        },

        async getPurposes() {
            const response = await this.$store.dispatch('loan/applications/get_purposes');
            this.purposes = response?.dataResult ?? [];
        },
        async getCustomerTypes() {
            await this.$store.dispatch('general/customersTypes/get_allCustomersTypes', { skip: 1, take: 9999 });
        },
        async getOfficers() {
            const response = await this.$store.dispatch('user/get_loanOfficers');
            this.officers = response.dataResult;
        },
        setData() {
            const {
                loanTypeId,
                loanAmount,
                repaymentMethod,
                purposeId,
                purposeDetails,
                purposeDescription,
                monthlyPayment,
                applicantInfo,
                coApplicantInfo,
                guarantorInfo,
                periodInMonths,
            } = this.loanApplication;

            // Asignar datos básicos de la aplicación de préstamo
            this.loanType = loanTypeId;
            this.amount = loanAmount;
            this.repaymentMethod = repaymentMethod;
            this.purpose = purposeId;
            this.purposeDetails = purposeDetails;
            this.purposeDescription = purposeDescription;
            this.monthlyPayment = monthlyPayment;
            this.selectedOfficer = this.loanApplication.agentInfo?.agentId;
            this.apr = this.loanApplication.apr;
            this.expectedAdminFee = this.loanApplication.expectedAdminFee;

            // Asignar información del solicitante
            if (applicantInfo) {
                this.reasonType = applicantInfo.reasonTypeId || null;
                this.applicant = { ...applicantInfo };
                this.applicant.nationalID = applicantInfo.additionalDetails?.nationalID || null;

                if (this.reasonType === 2) {
                    this.businessName = applicantInfo.businessName || '';
                }
            } else {
                this.applicant = null;
                this.reasonType = null;
                this.businessName = '';
            }

            // Asignar información del co-solicitante
            if (coApplicantInfo && coApplicantInfo.id !== 0) {
                this.coApplicant = { ...coApplicantInfo };
                this.coApplicant.nationalID = coApplicantInfo.additionalDetails?.nationalID || null;
            } else {
                this.coApplicant = null;
            }

            // Asignar información del garante
            if (guarantorInfo && guarantorInfo.id !== 0) {
                this.guarantor = { ...guarantorInfo };
                this.guarantor.nationalID = guarantorInfo.additionalDetails?.nationalID || null;
            } else {
                this.guarantor = null;
            }

            // Configurar montos mínimos y máximos, meses y pagos mensuales
            this.monthSelectedOption = periodInMonths || null;
            this.getMonths();
            this.getMonthlyPayment();
        },
    },
    created() {
        this.getPurposes();
        this.getCustomerTypes();
        this.getOfficers();
        this.setData();
    },
};
</script>
