<template>
    <div class="error-box">
        <div class="py-12">
            <div class="text-center">
                <h1 class="error-title error--text">401</h1>
                <h3 class="text-uppercase error-subtitle">ACCESS DENIED !</h3>
                <p class="text-muted mt-4 mb-4">YOU DON'T HAVE PERMISSIONS TO ACCESS THIS RESOURCE</p>
                <b-button variant="secondary" size="lg" href="/home">Back to Home</b-button>
                <b-button @click="goToLogin" variant="primary" size="lg" class="ml-2"> Login </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    data: () => ({}),
    methods: {
        async goToLogin() {
            await this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
    },
};
</script>

<style lang="scss">
.error-title {
    font-size: 210px;
    font-weight: 900;
    text-shadow:
        4px 4px 0 #fff,
        6px 6px 0 #343a40;
    line-height: 210px;
}

@media (max-width: 991px) {
    .error-title {
        font-size: 120px;
        line-height: 120px;
    }
}

@media (max-width: 767px) {
    .error-title {
        font-size: 40px;
        line-height: 40px;
    }
}
</style>
