export default function DrawerRoutes(authorizations, user) {
    return [
        { header: 'Total OCS' },
        // { header: "Total App", icon: "mdi mdi-dots-horizontal" },
        {
            id: 'home',
            icon: 'home',
            title: 'Home',
            to: '/home',
            active: authorizations.includes('/Home'),
        },
        {
            id: 'dashboard',
            icon: 'grid',
            title: 'Dashboard',
            to: '/dashboard',
            active: authorizations.includes('/Dashboards'),
        },
        {
            id: 'customers',
            icon: 'user',
            title: 'Customer/Companies',
            active: ['/Customer-GetAll', '/CustomerDocumentTypes-GetAll', '/CustomerTypes-GetAll'].some((string) =>
                authorizations.includes(string),
            ),
            childern: [
                {
                    icon: 'mdi mdi-account-multiple',
                    title: 'All Customers',
                    to: '/customers',
                    active: authorizations.includes('/Customer-GetAll'),
                },
                {
                    icon: 'mdi mdi-account-multiple',
                    title: 'All Companies',
                    to: '/customers/companies',
                    active: authorizations.includes('/Customer-GetAll'),
                },

                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Types',
                    to: '/customers/reasons-types',
                    active: authorizations.includes('/CustomerTypes-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Documents',
                    to: '/customers/documents-types',
                    active: authorizations.includes('/CustomerDocumentTypes-GetAll'),
                },
            ],
        },
        {
            id: 'loan-applications',
            icon: 'users',
            title: 'Loan Applications',
            i18n: 'Loan Applications',
            name: '/applications/',
            active: ['/LoanApplications-GetAll'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'All',
                    to: '/applications',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Assigned Me',
                    to: '/applications?slug=assigned',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
            ],
        },
        {
            id: 'loans',
            icon: 'dollar-sign',
            title: 'Loans',
            i18n: 'Loans',
            name: '/loans/',
            active: [
                '/LoanApplications-GetAll',
                '/LoanApplications-Approved',
                '/LoanApplications-Active',
                '/LoanApplications-Pending-Approved',
                '/LoanApplications-Closed',
            ].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'All',
                    to: '/loans/all',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Active in Arrears',
                    to: '/loans/active-in-arrears',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Active',
                    to: '/loans/active',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Approved',
                    to: '/loans/approved',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Pending Approval',
                    to: '/loans/pending-approval',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Closed',
                    to: '/loans/closed',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
            ],
        },
        {
            id: 'products',
            icon: 'package',
            title: 'Products',
            name: '/products/',
            active: ['/LoanTypes', '/Rules'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-calculator',
                    title: 'Rules',
                    to: '/products/rules',
                    active: authorizations.includes('/Rules'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Types',
                    to: '/products/types',
                    active: authorizations.includes('/LoanTypes'),
                },
            ],
        },
        {
            id: 'partners',
            icon: 'user-plus',
            title: 'Partners',
            name: '/loans-affiliates/',
            active: ['/Partners-GetList'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Index',
                    to: '/loans-affiliates',
                    active: authorizations.includes('/Partners-GetList'),
                },
                {
                    icon: 'mdi mdi-calculator',
                    title: 'Calculator',
                    to: '/loans-affiliates/calculator',
                    active: authorizations.includes('/Partners-GetList') && user.Roles[0] != 'Admin',
                },
            ],
        },
        {
            id: 'verifications',
            icon: 'briefcase',
            title: 'Verifications',
            name: '/verifications/',
            // active: ['/ScreeningVerification'].some((string) => authorizations.includes(string)),
            active: true,
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Screenings Requests',
                    to: '/verifications/screening-requests',
                    active: ['/ScreeningsRequest-GetAll'].some((string) => authorizations.includes(string)),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Screenings Verifications',
                    to: '/verifications/screening-verifications',
                    active: authorizations.includes('/Screenings-Verifications'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Jobs verifications',
                    to: '/verifications/job-verifications',
                    active: true,
                },
            ],
        },
        {
            id: 'general',
            icon: 'settings',
            title: 'General',
            i18n: 'General',
            name: '/general/',
            active: ['/Banks-GetAll', '/Countries-GetAll', '/Cities-GetAll', '/States-GetAll'].some((string) =>
                authorizations.includes(string),
            ),
            childern: [
                {
                    icon: 'mdi mdi-bank',
                    title: 'Banks',
                    to: '/general/banks',
                    active: authorizations.includes('/Banks-GetAll'),
                },
                {
                    icon: 'mdi mdi-map',
                    title: 'Countries',
                    to: '/general/countries',
                    active: authorizations.includes('/Countries-GetAll'),
                },
                {
                    icon: 'mdi mdi-city',
                    title: 'Cities',
                    to: '/general/cities',
                    active: authorizations.includes('/Cities-GetAll'),
                },
                {
                    icon: 'mdi mdi-map-marker-multiple',
                    title: 'States',
                    to: '/general/states',
                    active: authorizations.includes('/States-GetAll'),
                },
            ],
        },
        {
            id: 'authentication',
            icon: 'lock',
            title: 'Authentication',
            i18n: 'Authentication',
            name: '/authentication/',
            active: ['/Roles-GetAll', '/Users-GetAll'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-account-multiple',
                    title: 'Users',
                    to: '/security/users',
                    active: authorizations.includes('/Users-GetAll'),
                },
                {
                    icon: 'mdi mdi-account-convert',
                    title: 'Roles',
                    to: '/security/roles',
                    active: authorizations.includes('/Roles-GetAll'),
                },
            ],
        },
    ];
}
