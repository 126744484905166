import dayjs from 'dayjs';

export function formatDate(date, withTime = false) {
    if (!dayjs(date).isValid()) return '';

    if (!date) return '';

    if (withTime) return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
    return dayjs(date).format('DD/MM/YYYY');
}

export function formatCurrency(value) {
    if (!value && value != 0) return '';
    //Conver to AWG currency
    return value.toLocaleString('en-AW', { style: 'currency', currency: 'AWG' });
}
