<template>
    <b-form @submit.prevent="submitForm">
        <b-row>
            <!-- Brand -->
            <b-col md="3">
                <b-form-group label="Brand" label-for="brand">
                    <b-form-input
                        id="brand"
                        v-model="form.Brand"
                        type="text"
                        required
                        placeholder="Enter brand name"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Model -->
            <b-col md="3">
                <b-form-group label="Model" label-for="model">
                    <b-form-input
                        id="model"
                        v-model="form.Model"
                        type="text"
                        required
                        placeholder="Enter model"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Year -->
            <b-col md="3">
                <b-form-group label="Year" label-for="year">
                    <b-form-input
                        id="year"
                        v-model="form.Year"
                        type="number"
                        required
                        placeholder="Enter manufacturing year"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Color -->
            <b-col md="3">
                <b-form-group label="Color" label-for="color">
                    <b-form-input
                        id="color"
                        v-model="form.Color"
                        type="text"
                        required
                        placeholder="Enter color"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- VIN -->
            <b-col md="3">
                <b-form-group label="VIN" label-for="vin">
                    <b-form-input
                        id="vin"
                        v-model="form.VIN"
                        type="text"
                        required
                        placeholder="Enter VIN"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Condition -->
            <b-col md="3">
                <b-form-group label="Condition" label-for="condition">
                    <b-form-input
                        id="condition"
                        v-model="form.Condition"
                        type="text"
                        required
                        placeholder="Enter condition"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Mileage -->
            <b-col md="3">
                <b-form-group label="Mileage" label-for="mileage">
                    <b-form-input
                        id="mileage"
                        v-model="form.Mileage"
                        type="number"
                        required
                        placeholder="Enter mileage"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Price -->
            <b-col md="3">
                <b-form-group label="Price" label-for="price">
                    <b-form-input
                        id="price"
                        v-model="form.Price"
                        type="number"
                        required
                        placeholder="Enter price"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Appraisal -->
            <b-col md="3">
                <b-form-group label="Appraisal" label-for="appraisal">
                    <b-form-input
                        id="appraisal"
                        v-model="form.Appraisal"
                        type="number"
                        required
                        placeholder="Enter appraisal value"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Seller Name -->
            <b-col md="3">
                <b-form-group label="Seller Name" label-for="sellerName">
                    <b-form-input
                        id="sellerName"
                        v-model="form.SellerName"
                        type="text"
                        required
                        placeholder="Enter seller's name"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <!-- Seller Contact -->
            <b-col md="3">
                <b-form-group label="Seller Contact" label-for="sellerContact">
                    <b-form-input
                        id="sellerContact"
                        v-model="form.SellerContact"
                        type="text"
                        required
                        placeholder="Enter seller's contact"
                    ></b-form-input>
                </b-form-group>
            </b-col>

        </b-row>

        <!-- Submit Button -->
        <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="loadingData">
                <b-spinner small v-if="loadingData"></b-spinner>
                Submit
            </b-button>
        </div>
    </b-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {
                Brand: null,
                Model: null,
                Year: null,
                Color: null,
                VIN: null,
                Condition: null,
                Mileage: null,
                Price: null,
                Appraisal: null,
                SellerName: null,
                SellerContact: null,
                ModifiedBy: null,
            },
            loadingData: false,
        };
    },
    computed: {
        ...mapGetters({
            loanApplication: 'loan/applications/getLoanApplication',
        }),
    },
    methods: {
        async submitForm() {
            // this.loanType = this.loanApplication.loanTypeId;
            // this.amount = this.loanApplication.loanAmount;
            // this.repaymentMethod = this.loanApplication.repaymentMethod;
            // this.purpose = this.loanApplication.purposeId;
            // this.purposeDetails = this.loanApplication.purposeDetails;
            // this.purposeDescription = this.loanApplication.purposeDescription;
            // this.monthlyPayment = this.loanApplication.monthlyPayment;

            const payload = {
                id: this.loanApplication.id,
                loanTypeId: this.loanApplication.loanTypeId,
                periodInMonths: this.loanApplication.periodInMonths,
                loanAmount: this.loanApplication.loanAmount,
                repaymentMethod: this.loanApplication.repaymentMethod,
                purposeId: this.loanApplication.purposeId,
                purposeDetails: this.loanApplication.purposeDetails,
                purposeDescription: this.loanApplication.purposeDescription,
                customerId: this.$route.params.customer,
                origin: 1,
                isActive: true,
                applicationDetails: JSON.stringify(this.form),
            };

            this.loadingData = true;
            await this.$store.dispatch('loan/applications/update_loanApplication', payload);
            await this.$store.dispatch('loan/applications/get_loanApplicationById', this.$route.params.id);

            this.loadingData = false;
        },
    },

    created() {
        const vehicleData = JSON.parse(this.loanApplication.applicationDetails);
        this.form.Brand = vehicleData.Brand;
        this.form.Model = vehicleData.Model;
        this.form.Year = vehicleData.Year;
        this.form.Color = vehicleData.Color;
        this.form.VIN = vehicleData.VIN;
        this.form.Condition = vehicleData.Condition;
        this.form.Mileage = vehicleData.Mileage;
        this.form.Price = vehicleData.Price;
        this.form.Appraisal = vehicleData.Appraisal;
        this.form.SellerName = vehicleData.SellerName;
        this.form.SellerContact = vehicleData.SellerContact;
    },
};
</script>
