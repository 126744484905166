<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body class="mb-4">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-4" />
                </template>

                <!-- Search Control -->
                <div class="d-flex justify-content-between">
                    <b-form-group class="w-25">
                        <b-input-group>
                            <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button size="sm" variant="danger" :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center btn-grp" v-if="hasPermission">
                        <b-button variant="primary" v-b-modal.modal-upload-files class="d-flex align-items-center mr-0">
                            <feather type="upload" class="feather-sm"></feather>
                            <span class="ml-1">Upload File</span>
                        </b-button>
                    </div>
                </div>
                <!-- End Section -->

                <div>
                    <h4>Customer Documents</h4>
                    <b-table
                        style="min-height: 250px"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="documents"
                        :filter="filter"
                        :fields="fields"
                        hover
                    >
                        <template #cell(id)="data"> #{{ data.item.id }} </template>
                        <template #cell(createDate)="data"> {{ $formatDate(data.item.createDate) }} </template>
                        <template #cell(documentDate)="data"> {{ $formatDate(data.item.documentDate) }} </template>
                        <template #cell(documentExpirationDate)="data">
                            {{ $formatDate(data.item.documentExpirationDate) }}
                        </template>
                        <template #cell(isActive)="data">
                            <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                            <b-badge variant="danger" v-else>Archived</b-badge>
                        </template>

                        <template #cell(actions)="data">
                            <div class="d-flex">
                                <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                    <!-- <b-dropdown-item @click="goToView(data.item.sourcePath)">View</b-dropdown-item> -->

                                    <!-- DOCUMENT JWT SIGNED -->
                                    <b-dropdown-item @click="getDocumentLink(data.item)">View</b-dropdown-item>

                                    <b-dropdown-item @click="disableDocument(data.item, !data.item.isActive)">
                                        {{ data.item.isActive ? 'Archive' : 'Activate' }}
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        @click="removeDocument(data.item)"
                                        v-if="$validatePermission('/Customer-Delete-Documents')"
                                    >
                                        Delete
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </template>
                    </b-table>
                    <!-- End Section -->
                    <!-- Pagination -->
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                @input="getData"
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                @input="getData"
                                v-model="currentPage"
                                :total-rows="totalDcuments"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                    <!-- End Section -->
                </div>
            </b-card>

            <!-- Upload Modal -->
            <UploadDocumentModal @save="getData" />
        </div>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import { validatePermission } from '../../utils/auth';
import dayjs from 'dayjs';
import UploadDocumentModal from '../common/UploadDocumentModal.vue';
export default {
    name: 'CustomerDocuments',
    components: {
        Header,
        UploadDocumentModal,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                sortable: true,
            },
            {
                key: 'documentTypeName',
                label: 'Document Type',
                sortable: true,
            },
            {
                key: 'createDate',
                label: 'Upload Date',
                sortable: true,
            },
            {
                key: 'documentDate',
                label: 'Document Date',
            },
            {
                key: 'documentExpirationDate',
                label: 'Document Expiration Date',
            },
            {
                key: 'name',
                label: 'File Name',
                sortable: true,
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
            {
                key: 'actions',
                label: 'Actions',
                sortable: true,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        loading: false,
        currentRoute: window.location.pathname,
        files: [],
        loadingSave: false,
        tableFields: [
            { key: 'name', label: 'File Name' },
            { key: 'documentType', label: 'Document Type' },
            { key: 'documentDate', label: 'Document Date' },
            { key: 'documentExpirationDate', label: 'Expiration Date' },
            { key: 'actions', label: 'Actions' },
        ],
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            documents: 'customer/documents/getDocuments',
            totalDcuments: 'customer/documents/getTotalDocuments',
            documentTypes: 'customer/documents/getDocumentTypes',
        }),
        hasPermission() {
            if (this.$route.path.includes('companies')) return validatePermission('/Company-Add-Documents');
            return validatePermission('/Customer-Add-Documents');
        },
    },
    methods: {
        clearData() {
            this.file = null;
            this.files = [];
        },
        async getData() {
            this.loading = true;
            await this.$store.dispatch('customer/documents/get_documents', {
                skip: this.currentPage,
                take: this.perPage,
                customerId: this.$route.params.customer,
            });
            this.loading = false;
        },
        async getDocumentTypes() {
            await this.$store.dispatch('customer/documents/get_documentTypes');
        },
        addFiles(event) {
            const newFiles = Array.from(event.target.files || []);
            newFiles.forEach((file) => {
                this.files.push({
                    name: file.name,
                    file: file,
                    documentType: null,
                    documentDate: dayjs().format('YYYY-MM-DD'),
                    documentExpirationDate: null,
                });
            });
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        isExpDateRequiredForFile(documentTypeId) {
            const document = this.documentTypes.find((doc) => doc.id === documentTypeId);
            return document?.expirationDateRequired;
        },
        async removeDocument(document) {
            await this.$store.dispatch('customer/documents/remove_document', document.id);
            await this.getData();
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Strip metadata if needed
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async uploadFiles() {
            this.loadingSave = true;
            for (const item of this.files) {
                const blob = await this.fileToBase64(item.file);
                const data = {
                    fileName: item.name,
                    customerId: this.$route.params.customer,
                    documentTypeId: item.documentType,
                    documentDate: item.documentDate,
                    documentExpirationDate: item.documentExpirationDate,
                    file: blob,
                };

                await this.$store.dispatch('customer/documents/add_documentsInCDN', data);
            }
            await this.getData();
            this.loadingSave = false;
            this.clearData();
            this.$bvModal.hide('modal-upload-files');
        },
        goToView(path) {
            window.open(`${path}`, '_blank');
        },

        async getDocumentLink(document) {
            const id = document.id;
            if (!id) return;
            const result = await this.$store.dispatch('customer/documents/get_documentSignedById', document.id);
            if (result) {
                window.open(result, '_blank');
            }
        },

        async disableDocument(document, status) {
            let data = {
                id: document.id,
                name: document.name,
                sourcePath: document.sourcePath,
                isActive: status,
            };
            await this.$store.dispatch('customer/documents/update_documentStatus', data);
            await this.getData();
        },
    },
    mounted() {
        if (!this.new) {
            this.getData();
        }
    },
    created() {
        if (!this.new) {
            this.getData();
        }
        this.getDocumentTypes();
    },
};
</script>
