<template>
    <div>
        <b-dropdown-item @click="generatePDF">Generate Report</b-dropdown-item>
        <div style="display: none">
            <div class="report-container" id="container" style="width: 500px">
                <h6 class="text-center mb-4">Loan Applicant Report</h6>

                <div class="bordered-box" v-if="customer">
                    <div class="section-header">Customer Information</div>
                    <div class="row">
                        <div class="col-6">
                            <div class="data-item">
                                <span class="data-label">First Name:</span> {{ customer.firstName }}
                            </div>
                            <div class="data-item">
                                <span class="data-label">Middle Name:</span> {{ customer.middleName }}
                            </div>
                            <div class="data-item">
                                <span class="data-label">Last Name:</span> {{ customer.lastName }}
                            </div>
                            <div class="data-item">
                                <span class="data-label">Address:</span> {{ customer.address1 }}
                            </div>
                            <div class="data-item">
                                <span class="data-label">Date of Birth:</span>
                                {{ $formatDate(customer.additionalDetails.birthDate) }}
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="data-item">
                                <span class="data-label">Marital Status:</span> {{ maritalStatus(customer).name }}
                            </div>
                            <div class="data-item"><span class="data-label">Phone:</span> {{ customer.phone1 }}</div>
                            <div class="data-item"><span class="data-label">Email:</span> {{ customer.email1 }}</div>
                        </div>
                    </div>
                </div>

                <div class="bordered-box" v-if="coApplicant">
                    <div class="section-header">Co Applicant Information</div>
                    <div class="section-content">
                        <div class="data-item">
                            <span class="data-label">First Name:</span> {{ coApplicant.firstName }}
                        </div>
                        <div class="data-item">
                            <span class="data-label">Middle Name:</span> {{ coApplicant.middleName }}
                        </div>
                        <div class="data-item">
                            <span class="data-label">Last Name:</span> {{ coApplicant.lastName }}
                        </div>
                        <div class="data-item"><span class="data-label">Addres:</span> {{ coApplicant.address1 }}</div>
                        <div class="data-item">
                            <span class="data-label">Date of Birth:</span>
                            {{ $formatDate(coApplicant.additionalDetails.birthDate) }}
                        </div>
                        <div class="data-item">
                            <span class="data-label">Marital Status:</span> {{ maritalStatus(coApplicant).name }}
                        </div>
                        <div class="data-item"><span class="data-label">Phone:</span> {{ coApplicant.phone1 }}</div>
                        <div class="data-item"><span class="data-label">Email:</span> {{ coApplicant.email1 }}</div>
                    </div>
                </div>

                <div class="bordered-box">
                    <div class="section-header">Incomes Details</div>
                    <div class="section-content">
                        <b-table small :items="incomes" header :fields="fields">
                            <template #cell(type)="data">
                                <b>{{ data.item.type }}</b>
                            </template>
                            <template #cell(amount)="data"> AWG {{ data.item.amount }} </template>
                        </b-table>
                    </div>
                </div>

                <div class="bordered-box">
                    <div class="section-header">Expenses Details</div>
                    <div class="section-content">
                        <b-table small :items="expenses" :fields="fields">
                            <template #cell(type)="data">
                                <b>{{ data.item.type }}</b>
                            </template>
                            <template #cell(amount)="data"> AWG {{ data.item.amount }} </template>
                        </b-table>
                    </div>
                </div>
                <!-- 
                <div class="bordered-box">
                    <div class="section-header">Co-Applicant Information</div>
                    <div class="section-content">
                        <div class="data-item"><span class="data-label">Name:</span> Jane Doe</div>
                        <div class="data-item"><span class="data-label">Relationship:</span> Spouse</div>
                        <div class="data-item"><span class="data-label">Monthly Income:</span> $3,000</div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
export default {
    name: 'LoanApplicant',
    data() {
        return {
            applicant: {
                name: 'Juan Pérez',
                idNumber: '12345678',
                email: 'juan.perez@example.com',
                phone: '555-1234',
                address: 'Calle Falsa 123',
                amountRequested: '$10,000',
                monthlyIncome: '$3,500',
                monthlyExpenses: '$1,200',
                requestDate: '2024-08-24',
                applicationStatus: 'Under Review',
                additionalComments: 'Applicant has provided all required documentation.',
            },
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'type', label: 'Type' },
                { key: 'amount', label: 'amount' },
            ],
            customer: null,
            loanApplication: null,
            coApplicant: null,
            maritalStatusArray: [
                {
                    id: 1,
                    name: 'Casa',
                },
                {
                    id: 2,
                    name: 'Soltero/-a',
                },
                {
                    id: 3,
                    name: 'Biba Hunto',
                },
                {
                    id: 4,
                    name: 'Otro',
                },
            ],
            incomes: [],
            expenses: [],
        };
    },
    props: {
        data: Object,
    },
    methods: {
        async generatePDF() {
            this.customer = await this.$store.dispatch('customer/get_customer', this.data.customerId);
            const response = await this.$store.dispatch('loan/applications/get_loanById', this.data.id);
            this.loanApplication = response?.dataResult ?? null;

            if (this.loanApplication.coApplicantId) {
                this.coApplicant = await this.$store.dispatch(
                    'customers/get_customer',
                    this.loanApplication.coApplicantId,
                );
            }

            if (JSON.parse(this.loanApplication.incomes)) {
                this.incomes = JSON.parse(this.loanApplication.incomes).map((income) => {
                    return Object.keys(income).reduce((acc, key) => {
                        acc[key.toLowerCase()] = income[key];
                        return acc;
                    }, {});
                });
            }
            if (JSON.parse(this.loanApplication.expenses)) {
                this.expenses = JSON.parse(this.loanApplication.expenses).map((expense) => {
                    return Object.keys(expense).reduce((acc, key) => {
                        acc[key.toLowerCase()] = expense[key];
                        return acc;
                    }, {});
                });
            }

            const content = document.getElementById('container');

            // Apply temporary styles for adjusting content
            content.style.maxWidth = '500px';

            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: 'a4',
            });

            doc.html(content, {
                callback: (pdf) => {
                    // Save the PDF
                    pdf.save('user-report.pdf');

                    // Revert styles to original
                    content.style.maxWidth = '';
                },
                x: 10,
                y: 10,
                html2canvas: {
                    scale: 0.85, // Adjust scale factor if content is too large
                },
            });
        },
        maritalStatus(user) {
            return this.maritalStatusArray.find((el) => el.id == user?.additionalDetails?.maritalStatusId);
        },
    },
    computed: {},
};
</script>

<style scoped>
.container {
    max-width: 960px;
    font-size: 10px;
}

.report-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
    font-size: 8px;
}

.section-header {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 6px;
}

.section-content {
    margin-bottom: 10px;
}

.data-label {
    font-weight: bold;
    color: #495057;
}

.data-item {
    margin-bottom: 2px;
}

.data-item:last-child {
    margin-bottom: 0;
}

.bordered-box {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 8px;
}
</style>
