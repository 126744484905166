<template>
    <b-card no-body class="p-4">
        <b-form @submit.prevent="saveData">
            <b-card-body class="d-flex flex-column" style="row-gap: 12px">
                <b-row>
                    <b-col sm="3" class="d-none">
                        <label class="mb-1">Reason Type</label>
                        <b-form-radio-group
                            v-model="data.reasonTypeId"
                            :options="customersTypes"
                            class="d-flex"
                            value-field="id"
                            @change="changeReasonType"
                            text-field="name"
                            required
                        ></b-form-radio-group>
                    </b-col>
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label class="fw-medium mb-1" for="comp4">Gender</label>
                        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                            <b-form-radio-group
                                required
                                :options="[
                                    { text: 'Male', value: 'M' },
                                    { text: 'Female', value: 'F' },
                                ]"
                                text-field="text"
                                value-field="value"
                                :aria-describedby="ariaDescribedby"
                                name="input-gender"
                                v-model="data.gender"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label class="fw-medium mb-1" for="fname4">Title </label>
                        <b-form-select
                            size="sm"
                            v-model="data.titleId"
                            :options="[
                                { value: '', text: 'Please select an option' },
                                { text: 'Miss', value: 1 },
                                { text: 'Ms', value: 2 },
                                { text: 'Mr', value: 3 },
                                { text: 'Mrs', value: 4 },
                            ]"
                            required
                        />
                    </b-col>
                </b-row>

                <b-row v-if="data.reasonTypeId == 2">
                    <b-col sm="3">
                        <label class="mb-1">Business Name* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="Business name here"
                            v-model="data.businessName"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">Doing Business Name* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            v-model="data.doingBusinessName"
                            placeholder="Doing Business Name Here"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">KVK - nummer/Chamber of Commerce</label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="KVK - nummer Here"
                            v-model="data.businessId"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row v-if="data.reasonTypeId == 2">
                    <b-col sm="3">
                        <label class="mb-1">Business Address* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="Address here"
                            v-model="data.address2"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">Business Phone</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            placeholder="Phone number here"
                            v-model="data.phone2"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">Business Email</label>
                        <b-form-input
                            size="sm"
                            type="email"
                            placeholder="Email here"
                            v-model="data.email2"
                        ></b-form-input>
                    </b-col>
                </b-row>

                <h4 v-if="data.reasonTypeId == 2" class="mt-4">Contact Person Information</h4>

                <b-row style="row-gap: 12px">
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label class="mb-1">National ID* </label>
                        <b-form-input
                            size="sm"
                            id="title4"
                            type="text"
                            minLength="8"
                            maxLength="8"
                            placeholder="National ID here"
                            v-model="data.nationalID"
                            :state="isNationalIDExist ? false : null"
                            @blur="verifyNationalID"
                        ></b-form-input>
                        <span class="text-danger">
                            <small v-if="isNationalIDExist">This National ID already exists</small>
                        </span>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">First Name<span v-if="data.reasonTypeId == 1">*</span> </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="First name here"
                            v-model="data.firstName"
                            :required="data.reasonTypeId == 1"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label class="mb-1">Middle Name </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="Name here"
                            v-model="data.middleName"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">Last Name<span v-if="data.reasonTypeId == 1">*</span> </label>
                        <b-form-input
                            size="sm"
                            id="title4"
                            type="text"
                            placeholder="Last name here"
                            v-model="data.lastName"
                            :required="data.reasonTypeId == 1"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="row-gap: 12px" v-if="data.reasonTypeId == 1">
                    <b-col sm="3">
                        <label class="mb-1">Address</label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="Address here"
                            v-model="data.address1"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label class="mb-1">District </label>
                        <b-form-select
                            size="sm"
                            v-model="data.cityId"
                            :options="[{ id: null, name: 'Please select an option', disabled: true }, ...cities]"
                            text-field="name"
                            value-field="id"
                        ></b-form-select>
                    </b-col>
                </b-row>
                <b-row style="row-gap: 12px">
                    <b-col sm="3">
                        <label class="mb-1" v-if="data.reasonTypeId == 1">Phone 1*</label>
                        <label class="mb-1" v-else>Phone</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            placeholder="Phone number here"
                            v-model="data.phone1"
                            :required="data.reasonTypeId == 1"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label class="mb-1">Phone 2</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            placeholder="Phone number here"
                            v-model="data.phone2"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="row-gap: 12px">
                    <b-col sm="3">
                        <label class="mb-1">Email 1 <span v-if="data.reasonTypeId == 1">*</span></label>
                        <b-form-input
                            type="email"
                            size="sm"
                            placeholder="Email here"
                            v-model="data.email1"
                            :required="data.reasonTypeId == 1"
                        />
                    </b-col>

                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label class="mb-1">Email 2</label>
                        <b-form-input
                            size="sm"
                            type="email"
                            placeholder="Email here"
                            v-model="data.email2"
                        ></b-form-input>
                    </b-col>
                </b-row>
            </b-card-body>
            <hr class="my-4" />
            <div v-if="hasPermission">
                <div class="btn-grp text-right">
                    <b-button :disabled="loadingSave || isNationalIDExist" variant="primary" class="px-4" type="submit">
                        <b-spinner small v-if="loadingSave"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Customer',
    data: () => ({
        optionsCountry: [
            { value: null, text: 'Please select an option' },
            { value: 'Aruba', text: 'Aruba' },
            { value: 'US', text: 'Estados Unidos' },
        ],
        loadingCustomerTypes: true,
        loadingVerification: true,
        data: {
            reasonTypeId: 1,
            nationalID: '',
            firstName: '',
            middleName: '',
            lastName: '',
            businessId: '',
            gender: null,
            doingBusinessName: '',
            businessName: '',
            titleId: null,
            cityId: null,
            address1: '',
            address2: '',
            address3: '',
            phone1: '',
            phone2: '',
            email1: '',
            email1IsVerified: true,
            email2: '',
            email2IsVerified: true,
        },
        loan: null,
        loadingSave: false,
        isNationalIDExist: false,
    }),
    computed: {
        ...mapGetters({
            customersTypes: 'general/customersTypes/getcustomersTypes',
            countries: 'general/countries/getCountries',
            cities: 'general/cities/getCities',
            authorizations: 'auth/getAuthorizations',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Add');
        },
    },
    methods: {
        async verifyNationalID() {
            const response = await this.$store.dispatch('mambu/validate_ifCustomerExistOrCreate', {
                id: this.data.nationalID,
            });
            this.isNationalIDExist = response ? true : false;
        },
        async getCustomerTypes() {
            this.loadingCustomerTypes = true;
            await this.$store.dispatch('general/customersTypes/get_allCustomersTypes', { skip: 1, take: 9999 });
            this.loadingCustomerTypes = false;
        },
        async getData() {
            await this.$store.dispatch('general/countries/get_allCountries', { skip: 1, take: 9999 });
            await this.$store.dispatch('general/cities/get_allCities', { skip: 1, take: 9999 });
        },
        async saveData() {
            this.loadingSave = true;
            const response = await this.$store.dispatch('customer/add_customer', this.data);
            if (response == undefined) {
                this.loadingSave = false;
                return;
            }
            const id = response.dataResult;
            if (this.$route.query.type == 'business') {
                this.$router.push(`/customers/companies/${id}`);
            } else {
                this.$router.push(`/customers/list/${id}`);
            }

            this.loadingSave = false;
        },
        changeReasonType() {
            this.$router.push({
                query: { type: this.data.reasonTypeId == 2 ? 'business' : 'person' },
            });
        },
    },
    mounted() {
        const type = this.$route.query.type;
        if (type) {
            this.data.reasonTypeId = parseInt(type == 'business' ? 2 : 1);
        }
        this.getCustomerTypes();
        this.getData();
    },
};
</script>
