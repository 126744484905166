<template>
    <div v-if="customer && customer.id != 0">
        <h5>{{ customer.firstName }} {{ customer.middleName }} {{ customer.lastName }}</h5>
        <div
            v-for="item in debtsAndIncomes"
            :key="item.id"
            class="d-flex justify-content-between p-1"
            :style="{
                background: item.type == 'Expense' ? '#FFD2D2' : '',
            }"
        >
            <label class="mb-0">{{ item.description }}</label>
            <ItemText :text="` ${item.type == 'Expense' ? '-' : ''} ${$formatCurrency(item.amount)}`" />
        </div>

        <!-- <div class="d-flex justify-content-between p-1">
            <label>Annuity</label>
            <ItemText :text="loanApplication.summaryInfo.monthlyPayment" isCurrency />
        </div> -->

        <div class="d-flex justify-content-between border-top pt-3 mt-3">
            <h5>Remaining Income</h5>
            <ItemText :text="remainingIncome" isCurrency />
        </div>
        <div class="d-flex justify-content-between">
            <h5>Debt Ratio</h5>
            <ItemText :text="debtRatio + '%'" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ItemText from '../../ItemText.vue';
export default {
    name: 'IndividualSummary',
    components: {
        ItemText,
    },
    props: {
        customerType: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: true,
        customer: null,
    }),
    computed: {
        ...mapGetters({
            currentCustomer: 'customer/getCustomer',
            loan: 'loan/applications/getLoan',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        debtsAndIncomes() {
            return this.customer?.individualSummaryInfo?.debtsAndIncomes ?? [];
        },
        debtRatio() {
            return this.customer?.individualSummaryInfo?.debtRatio ?? 0;
        },
        remainingIncome() {
            return this.customer?.individualSummaryInfo?.remainingIncome ?? 0;
        },
    },
    methods: {
        async setData() {
            if (this.customerType === 'applicant') {
                this.customer = this.loanApplication.applicantInfo;
            }
            if (this.customerType === 'coApplicant') {
                this.customer = this.loanApplication.coApplicantInfo;
            }
            if (this.customerType === 'guarantor') {
                this.customer = this.loanApplication.guarantorInfo;
            }
        },
    },
    created() {
        this.setData();
    },
};
</script>
