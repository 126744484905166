export default [
    {
        path: 'customers',
        redirect: { name: 'Customer List' },
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
            {
                name: 'Customer List',
                path: 'list',
                component: () => import('@/views/customers/CustomerList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Customer-GetAll'],
                },
            },
            {
                name: 'Customer Detail',
                path: 'list/:customer',
                component: () => import('@/views/customers/CustomerDetail'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Customer-GetAll'],
                },
            },
            {
                name: 'New Customer',
                path: 'new',
                component: () => import('@/views/customers/CustomerCreate'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Customer-Add'],
                },
            },
            {
                name: 'Customers To Builders',
                path: 'list-to-builders',
                component: () => import('@/views/customers/CustomerListToBuilders'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Partners-GetList'],
                },
            },
            {
                name: 'Documents Types',
                path: 'documents-types',
                component: () => import('@/views/customers/CustomerDocumentTypes'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/CustomerDocumentTypes-GetAll'],
                },
            },
            {
                name: 'Reasons Types',
                path: 'reasons-types',
                component: () => import('@/views/general/CustomerReasonsTypes'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/CustomerTypes-GetAll'],
                },
            },
            {
                name: 'Companies',
                path: 'companies',
                component: () => import('@/views/business/BusinessList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Company-GetAll'],
                },
            },

            {
                name: 'Companies Detail',
                path: 'companies/:customer',
                component: () => import('@/views/business/BusinessDetail'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Company-GetAll'],
                },
            },
        ],
    },
];
