export default [
    {
        path: 'applications',
        redirect: { name: 'Loans Applications' },
        component: () => import('@/layouts/ApplicationLayout'),
        children: [
            {
                name: 'Loans Applications',
                path: 'list',
                component: () => import('@/views/loan-application/LoanApplicationList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/LoanApplications-GetAll'],
                },
            },
            {
                name: 'Loan Application',
                path: 'list/:id',
                component: () => import('@/views/loan-application/LoanApplicationDetail'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/LoanApplications-GetAll'],
                },
            },
            {
                name: 'New Loan',
                path: 'new',
                component: () => import('@/views/loan-application/LoanApplicationCreate'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/LoanApplications-Add'],
                },
            },
        ],
    },
];
