<template>
    <div
        id="login"
        style="min-height: calc(100vh - 20px)"
        class="fill-height d-flex align-items-center justify-content-center"
        tag="section"
    >
        <b-row class="justify-content-center no-gutters">
            <b-col lg="9" md="9" class="d-flex align-items-center">
                <b-card class="w-100" style="min-width: 400px" no-body>
                    <div class="p-4">
                        <img style="width: 100px" src="@/assets/images/TOTAL-Logotype-Teal.png" />
                        <h3 class="font-weight-bold mt-4">Recovery Password</h3>
                        <b-form @submit.prevent="resetPwd" v-if="!sent">
                            <b-form-group>
                                <b-form-input
                                    id="txt-pwd1"
                                    type="password"
                                    placeholder="Enter password"
                                    class="mb-1"
                                    v-model="pwd1"
                                    :state="pwd1State"
                                    @blur="validatePwd1"
                                ></b-form-input>
                                <b-form-invalid-feedback id="txt-pwd1"
                                    >This is a required field</b-form-invalid-feedback
                                >
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="txt-pwd2"
                                    type="password"
                                    placeholder="Confirm Password"
                                    class="mb-1"
                                    v-model="pwd2"
                                    :state="pwd2State"
                                    @blur="validatePwd2"
                                ></b-form-input>
                                <b-form-invalid-feedback id="txt-pwd2"
                                    >This is a required field</b-form-invalid-feedback
                                >
                            </b-form-group>

                            <span class="text-danger" v-if="!samePwdState">Passwords do not match</span>

                            <b-button type="submit" :disabled="loading" variant="primary" size="lg" block class="mt-4">
                                <b-spinner small v-if="loading"></b-spinner>
                                <span v-else>Send Password</span>
                            </b-button>
                        </b-form>
                        <div v-else>
                            <b-alert variant="success" show>Password has been updated successfully.</b-alert>

                            <b-button @click="goToLogin" variant="primary" size="lg" block class="mt-4">
                                Login
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'BoxedLogin',
    data: () => ({
        pwd1: '',
        pwd2: '',
        checkbox: false,
        pwd1State: null,
        pwd2State: null,
        samePwdState: true,
        sent: false,
        loading: false,
    }),
    computed: {},
    methods: {
        goToLogin() {
            this.$router.push('/login');
        },
        async resetPwd() {
            if (this.pwd1 == '') {
                this.pwd1State = false;
                return;
            }
            if (this.pwd2 == '') {
                this.pwd2State = false;
                return;
            }

            if (this.pwd1 != this.pwd2) {
                this.samePwdState = false;
                return;
            }
            this.samePwdState = true;
            this.loading = true;

            let data = {
                UserId: this.$route.params.id,
                Token: this.$route.query.Token,
                Password: this.pwd1,
            };
            await this.$store.dispatch('auth/resetPwd', data);
            this.loading = true;
            this.sent = true;
            this.pwd1 = '';
            this.pwd2 = '';
        },
        validatePwd1() {
            if (this.pwd1 == '') {
                this.pwd1State = false;
                return;
            }
            this.pwd1State = true;
        },
        validatePwd2() {
            if (this.pwd2 == '') {
                this.pwd2State = false;
                return;
            }
            this.pwd2State = true;
        },
    },
};
</script>
