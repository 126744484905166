<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="px-4">
            <div style="row-gap: 20px; display: flex; flex-direction: column">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="m-0" />
                </template>

                <!-- add an accordion here -->
                <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.applicant variant="light">
                                <h4 class="mb-0">Applicant</h4>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="applicant" visible accordion="applicant-accordion" role="tabpanel">
                            <b-card-body>
                                <DetailsContactInformation :applicant="applicant" />
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card v-if="coApplicant && coApplicant.id != 0" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.coApplicant variant="light">
                                <h4 class="mb-0">Co-Applicant</h4>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="coApplicant" visible accordion="applicant-accordion" role="tabpanel">
                            <b-card-body>
                                <DetailsContactInformation :applicant="coApplicant" />
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card v-if="guarantor && guarantor.id != 0" no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.guarantor variant="light">
                                <h4 class="mb-0">Guarantor</h4>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="guarantor" visible accordion="applicant-accordion" role="tabpanel">
                            <b-card-body>
                                <DetailsContactInformation :applicant="guarantor" />
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import DetailsContactInformation from './details/DetailsContactInformation.vue';
export default {
    name: 'ApplicationDetails',
    components: {
        Header,
        DetailsContactInformation,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        loading: false,
        applicant: null,
        coApplicant: null,
        guarantor: null,
    }),
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
            countries: 'general/countries/getCountries',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Additional-Details');
        },
        placeOfBirthName() {
            if (!this.applicant?.additionalDetails?.placeOfBirthId) return '';
            return this.countries.find((country) => country.id == this.applicant.additionalDetails.placeOfBirthId)
                ?.name;
        },
        genderName() {
            return this.applicant?.additionalDetails?.gender == 'F' ? 'Female' : 'Male';
        },
    },
    methods: {
        goToCoApplicantDetail() {
            this.$router.push(`/customers/list/${this.coApplicant.id}`);
        },
        goToGuarantorDetail() {
            this.$router.push(`/customers/list/${this.guarantor.id}`);
        },
        goToApplicantDetail() {
            this.$router.push(`/customers/list/${this.applicant.id}`);
        },
    },
    created() {
        this.applicant = this.loanApplication.applicantInfo;
        this.coApplicant = this.loanApplication.coApplicantInfo;
        this.guarantor = this.loanApplication.guarantorInfo;
    },
};
</script>
