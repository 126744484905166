import axios from 'axios';

const url = 'customers/Api/';

export default {
    namespaced: true,
    state() {
        return {
            customers: [],
            customersToBuilders: [],
            totalCustomers: 0,
            customer: {},
        };
    },
    getters: {
        getCustomers(state) {
            return state.customers;
        },
        getCustomersToBuilders(state) {
            return state.customersToBuilders;
        },
        getTotalCustomers(state) {
            return state.totalCustomers;
        },
        getCustomer(state) {
            return state.customer;
        },
    },
    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers;
        },
        SET_TOTAL_CUSTOMERS(state, totalCustomers) {
            state.totalCustomers = totalCustomers;
        },
        SET_CUSTOMER(state, customer) {
            state.customer = customer;
        },
    },
    actions: {
        async get_allCustomers({ commit }, payload) {
            try {
                let path = `${url}Profiles/GetAllCustomers?Page=${payload.skip}&RecordsPerPage=${payload.take}&SortDirection=${payload.sortDirection ?? ''}&SortProperty=${payload.sortProperty ?? ''}`;
                if (payload.status != null) {
                    path = path + `&Status=${payload.status}`;
                }
                path = path + `&SearchParam=${payload.query}`;
                const response = await axios.get(path);
                commit('SET_CUSTOMERS', response.data.dataResult);
                commit('SET_TOTAL_CUSTOMERS', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting profiles', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_allBusiness(_, payload) {
            try {
                let path = `${url}Profiles/GetAllCompanies?Page=${payload.skip}&RecordsPerPage=${payload.take}&SearchParam=${payload.query}`;
                path = path + `&SearchParam=${payload.query}`;
                const response = await axios.get(path);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting profiles', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_allToBuilders(_, payload) {
            try {
                const response = await axios.get(
                    `${url}Profiles/GetAllToBuilders?Page=${payload.skip}&RecordsPerPage=${payload.take}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting profiles', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_customer({ commit }, id) {
            try {
                const response = await axios.get(`${url}Profiles/GetById/${id}`);
                commit('SET_CUSTOMER', response.data.dataResult);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting profile', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_reference(_, id) {
            try {
                const response = await axios.get(`${url}Profiles/GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting profile', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_customerDetail(_, id) {
            try {
                const response = await axios.get(`${url}Profiles/GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting profile', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async add_customer({ rootGetters }, data) {
            try {
                const response = await axios.post(`${url}Profiles/Add`, {
                    ...data,
                    address3: '',

                    additionalDetails: {
                        nationalID: data.nationalID,
                        passport: '',
                        placeOfBirthId: null,
                        nationalityId: null,
                        birthDate: null,
                        bankId: null,
                        bankAccountNumber: '',
                        gender: data.gender,
                        maritalStatusId: null,
                        spousesFullName: '',
                        existingBankDebt: 0,
                        existingDebtAmount: 0,
                        otherInstitutionName: '',
                        numberDependentPersons: 0,
                    },
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    origin: 2,
                });
                this._vm.$bvToast.toast('Profile added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding profile', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_customer({ rootGetters }, data) {
            try {
                const response = await axios.put(`${url}Profiles/Update`, {
                    ...data,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                    isActive: true,
                });
                this._vm.$bvToast.toast('Profile updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating profile', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_customerStatus({ rootGetters }, data) {
            try {
                const response = await axios.put(`${url}Profiles/UpdateStatus`, {
                    customerId: data.id,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                    isActive: data.isActive,
                });
                this._vm.$bvToast.toast('Profile status updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating profile status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async send_emailVerification({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}Profiles/SendEmailVerification`, {
                    customerId: payload.customerId,
                    email: payload.email,
                    emailNumber: payload.emailNumber,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully verification.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error sending verification', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_statusEmailVerification(_, payload) {
            try {
                const response = await axios.put(`${url}Profiles/UpdateStatusEmailVerification`, {
                    customerId: payload.id,
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                    email: payload.email,
                    emailNumber: payload.emailNumber,
                });
                this._vm.$bvToast.toast('Successfully status updated.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return {
                    data: response,
                    success: true,
                };
            } catch (error) {
                this._vm.$bvToast.toast('Error updating status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return {
                    success: false,
                };
            }
        },
        async update_pepInformation({ rootGetters }, payload) {
            try {
                const response = await axios.put(`${url}Profiles/UpdatePEPInformation`, {
                    ...payload,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully PEP information updated.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return {
                    data: response,
                    success: true,
                };
            } catch (error) {
                this._vm.$bvToast.toast('Error updating PEP information', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return {
                    success: false,
                };
            }
        },
    },
};
