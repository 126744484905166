<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                    <Header />
                    <hr class="mb-4" />

                    <div>
                        <div class="d-flex justify-content-end align-items-center mb-3" style="gap: 16px">
                            <!-- //show modal button -->
                            <b-button variant="primary" @click="$bvModal.show('modal-templates')">
                                Show Templates
                            </b-button>
                            <b-button
                                v-if="loanApplication.loanProcessStatusLog.currentStatusCode != '220'"
                                variant="secondary"
                                @click="$bvModal.show('modal-upload-file')"
                            >
                                Upload File
                            </b-button>
                        </div>

                        <b-table
                            style="min-height: 250px"
                            show-empty
                            responsive
                            small
                            class="mb-0"
                            head-variant="light"
                            :items="documents"
                            :fields="fields"
                            hover
                        >
                            <template v-slot:cell(createDate)="data">
                                {{ $formatDate(data.item.createDate) }}
                            </template>
                            <template v-slot:cell(modifiedDate)="data">
                                {{ $formatDate(data.item.modifiedDate) }}
                            </template>

                            <template v-slot:cell(actions)="data">
                                <b-dropdown variant="primary" text="Actions" right>
                                    <b-dropdown-item @click="showViewModal(data.item)">View</b-dropdown-item>
                                    <b-dropdown-item @click="showDeleteModal(data.item)">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>

                        <!-- <div class="d-flex justify-content-between align-items-center mb-3 mt-5">
                            <h3 class="">Sign Process</h3>

                            <b-button
                                v-if="hasPermissionSign"
                                :variant="'primary'"
                                :disabled="loadingSignProcess"
                                @click="createSignProcess"
                            >
                                <b-spinner small v-if="loadingSignProcess"></b-spinner>
                                Start Process
                            </b-button>
                        </div>

                        <b-form-group label="Type of signature selected" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                v-model="signSelected"
                                :options="[
                                    { text: 'Digital Sign', value: 'digital' },
                                    { text: 'Manual Sign', value: 'manual' },
                                ]"
                                :aria-describedby="ariaDescribedby"
                                name="sign"
                                @change="updateSignature"
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-list-group v-if="signProcess.length > 0">
                            <b-list-group-item>
                                <div
                                    class="d-flex w-100 justify-content-between align-items-center border-bottom mb-2"
                                    v-for="item in signProcess"
                                    :key="item.code"
                                >
                                    <div>
                                        <h5>{{ item.documentStatusKey }}</h5>
                                        <p class="text-muted">{{ item.createDate.split('T')[0] }}</p>
                                    </div>
                                    <feather type="check-circle" class="feather-md text-success"></feather>
                                </div>
                            </b-list-group-item>
                        </b-list-group> -->
                    </div>
                </b-card-body>
            </b-card>
        </b-col>

        <b-col cols="12">
            <b-modal id="modal-templates" hide-footer centered title="Disable comment">
                <b-card-text>
                    <div
                        class="border-bottom d-flex justify-content-between py-2"
                        v-for="template in templates"
                        :key="template.id"
                    >
                        <h6 class="message-title text-primary mb-0 mt-1">{{ template.name }}</h6>
                        <ContractReport v-if="template.id == 1" :data="dataReport" />
                    </div>
                </b-card-text>
            </b-modal>

            <!-- Modal Upload File -->
            <b-modal id="modal-upload-file" hide-footer centered title="Upload File">
                <b-card-text>
                    <b-form-file
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <b-button variant="primary" class="mt-3" @click="uploadFile">Upload</b-button>
                </b-card-text>
            </b-modal>

            <!-- Modal Delete confirmation -->
            <b-modal id="modal-delete" hide-footer centered title="Delete Confirmation">
                <b-card-text>
                    <p>Are you sure you want to delete this document?</p>
                </b-card-text>
                <div class="d-flex justify-content-end" style="gap: 8px">
                    <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">Cancel</b-button>
                    <b-button variant="danger" @click="deleteDocument">Delete</b-button>
                </div>
            </b-modal>

            <b-modal id="modal-view-file" hide-footer centered title="View File">
                <b-card-text v-if="fileSelected">
                    <h4>{{ fileSelected.name }}</h4>
                    <div style="overflow: auto; width: 600px; height: 500px">
                        <iframe frameborder="0"></iframe>
                    </div>
                </b-card-text>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import ContractReport from '../reports/ContractReport.vue';
export default {
    name: 'ApplicationDigitalSign',
    components: {
        Header,
        ContractReport,
    },

    data: () => ({
        fields: [
            { key: 'id', label: 'ID' },
            { key: 'name', label: 'Name' },
            { key: 'createDate', label: 'Create Date' },
            { key: 'modifiedDate', label: 'ModifiedDate' },
            { key: 'actions', label: 'Actions' },
        ],
        documents: [],

        file: null,

        loading: false,
        loadingSignProcess: false,
        status: null,
        serviceSelected: null,
        signSelected: null,
        signProcess: [],
        loadingAddComment: false,
        comment: '',
        dataReport: {
            loanNumber: '',
            loanOfficer: '',
            applicantName: '',
            coApplicantName: '',
            applicantAddress: '',
            applicantIdNumber: '',
            principalBalance: '',
            apr: '',
            interest: '',
            totalInterestPrincipal: '',
            monthlyPayment: '',
            loanTermInMonths: '',
            lastMonthlyPayment: '',
            lastPaymentDate: '',
            firstPaymentDate: '',
            contractDate: '',
            loanOfficerName: '',
        },
        fileSelected: null,
        templates: [],
    }),
    methods: {
        async getData() {
            await this.$store.dispatch('customer/productRequest/get_processResquest', this.$route.params.id);
            await this.getSignProcess();
        },
        async getSignProcess() {
            const response = await this.$store.dispatch('loan/signature/get_signProcess', this.$route.params.id);
            this.signProcess = response;
        },
        async createSignProcess() {
            this.loadingSignProcess = true;
            await this.$store.dispatch(`loan/signature/create_signProcess`, this.$route.params.id);
            await this.getData();
            this.loadingSignProcess = false;
        },
        async updateSignature() {
            await this.$store.dispatch(`loan/applications/update_typeSignature`, {
                id: this.$route.params.id,
                isDigitalSignature: this.signSelected == 'digital',
            });
            await this.getData();
        },

        setReportData() {
            this.dataReport = {
                loanNumber: this.loanApplication.id,
                loanOfficer: this.loanApplication.agentInfo.agentName,
                applicantName:
                    this.loanApplication.applicantInfo.firstName + ' ' + this.loanApplication.applicantInfo.lastName,
                coApplicantName:
                    this.loanApplication.coApplicantInfo.firstName +
                    ' ' +
                    this.loanApplication.coApplicantInfo.lastName,
                applicantAddress: this.loanApplication.applicantInfo.address1,
                applicantIdNumber: this.loanApplication.applicantInfo.id,
                principalBalance: this.loanApplication.summaryInfo.loanAmount,
                apr: this.loanApplication.summaryInfo.apr,
                interest: this.loanApplication.summaryInfo.interestRate,
                totalInterestPrincipal:
                    this.loanApplication.summaryInfo.totalAmount +
                    (this.loanApplication.summaryInfo.interestRate / 100) *
                        this.loanApplication.summaryInfo.totalAmount,
                monthlyPayment: this.loanApplication.summaryInfo.monthlyPayment,
                loanTermInMonths: this.loanApplication.summaryInfo.periodInMonths,
                lastMonthlyPayment: '',
                lastPaymentDate: '',
                firstPaymentDate: '',
                contractDate: this.loanApplication.createAt,
                loanOfficerName: this.loanApplication.agentInfo.agentName,
            };
        },

        async getTemplates() {
            const response = await this.$store.dispatch('loan/printing/get_allTemplates');
            this.templates = response;
        },
        async getDocuments() {
            const response = await this.$store.dispatch('loan/printing/get_allSignedDocuments');
            this.documents = response;
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async uploadFile() {
            if (!this.file) {
                this.$bvToast.toast('Please select a file', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }

            const file = await this.fileToBase64(this.file);
            const payload = {
                fileName: this.file.name,
                file,
                printingDocumentId: 1,
                loanApplicationId: this.$route.params.id,
            };
            await this.$store.dispatch('loan/printing/create_signedDocument', payload);
            await this.getDocuments();
            this.$bvModal.hide('modal-upload-file');
        },

        showViewModal(item) {
            this.fileSelected = item;
            this.$bvModal.show('modal-view-file');
        },

        showDeleteModal(item) {
            this.$bvModal.show('modal-delete');
            this.fileSelected = item;
        },

        async deleteDocument() {
            await this.$store.dispatch('loan/printing/remove_signedDocument', this.fileSelected.id);
            await this.getDocuments();
            this.$bvModal.hide('modal-delete');
            this.fileId = null;
        },
    },
    computed: {
        ...mapGetters({
            process: 'customer/productRequest/getProcess',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermissionSign() {
            return this.authorizations.includes('/LoanApplications-Signature');
        },
    },
    created() {
        this.getSignProcess();
        this.setReportData();
        this.getData();
        this.getTemplates();
        this.getDocuments();
    },
};
</script>
