<template>
    <div>
        <b-card no-body>
            <b-form @submit.prevent="saveData" class="row">
                <div class="col-6" v-if="isFromScreening">
                    <label>Search an applicant</label>
                    <SearchSelect
                        store="customer/get_allCustomers"
                        text="firstName"
                        value="id"
                        @option-selected="handleOption"
                    />
                </div>

                <div v-if="!isFromScreening" class="col-6">
                    <label class="fw-medium mb-1" for="document-types">Customer Type</label>
                    <b-form-select
                        id="document-types"
                        :options="[
                            { value: null, text: 'Please select an option', disabled: true },
                            ...optionsCustomer,
                        ]"
                        v-model="customerType"
                        required
                    ></b-form-select>
                </div>
                <b-form-group label="Bank" label-for="bank" class="col-6">
                    <b-form-select v-model="bankSelected" id="bank" required>
                        <option :value="null" disabled>Select a bank</option>
                        <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{ bank.name }}</option>
                    </b-form-select>
                </b-form-group>
                <!-- Campo: Tipo de obligación -->
                <b-form-group label="Type" label-for="obligationtype" class="col-6">
                    <b-form-select v-model="obligationtype" id="obligationtype" required>
                        <option :value="null" disabled>Select obligation type</option>
                        <option value="1">Loan</option>
                        <option value="2">Credit Card</option>
                        <option value="3">Mortgage</option>
                        <option value="4">Other</option>
                    </b-form-select>
                </b-form-group>

                <!-- Campo: Fecha -->
                <b-form-group label="Start Date" label-for="obligationdate" class="col-6">
                    <b-form-input
                        type="date"
                        v-model="obligationdate"
                        id="obligationdate"
                        placeholder="Enter date"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Amount" label-for="amount" class="col-6">
                    <b-form-input
                        v-model="amount"
                        id="amount"
                        type="number"
                        required
                        placeholder="Enter amount"
                        step="0.01"
                    ></b-form-input>
                </b-form-group>

                <!-- Monthly Payment Field -->
                <b-form-group label="Monthly Payment" label-for="monthlypayment" class="col-6">
                    <b-form-input
                        v-model="monthlypayment"
                        id="monthlypayment"
                        type="number"
                        required
                        placeholder="Enter monthly payment"
                        step="0.01"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Loan Status" label-for="loanStatus" class="col-6">
                    <b-form-select v-model="loanStatus" id="loanStatus" :required="amount > 0 || monthlypayment > 0">
                        <option :value="null" disabled>Select loan status</option>
                        <option value="UTD">UTD</option>
                        <option value="IN ARREARS">IN ARREARS</option>
                    </b-form-select>
                </b-form-group>

                <b-form-group label="Comment" label-for="comment" class="col-6">
                    <b-form-input v-model="comment" id="comment" placeholder="Enter comment"></b-form-input>
                </b-form-group>
                <div class="d-flex justify-content-end mb-3 col-12">
                    <b-button variant="primary" type="submit" :disabled="loading">
                        <b-spinner small v-if="loading"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchSelect from '@/components/SearchSelect.vue';
export default {
    name: 'Obligations',
    components: {
        SearchSelect,
    },
    props: {
        isFromScreening: Boolean,
    },
    data() {
        return {
            // Fields and states
            bankSelected: null,
            obligationtype: null,
            obligationdate: '',
            amount: null,
            monthlypayment: null,
            comment: '',
            customerType: null,
            loading: false,
            obligations: [],
            loanStatus: null,
            banks: [],
            fields: [
                { key: 'bankid', label: 'Bank Id' },
                { key: 'bankname', label: 'Bank Name' },
                { key: 'obligationtype', label: 'Obligation Type' },
                { key: 'obligationdate', label: 'Date' },
                { key: 'amount', label: 'Amount' },
                { key: 'monthlypayment', label: 'Monthly Payment' },
                { key: 'action', label: 'Action' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            loanApplication: 'loan/applications/getLoanApplication',
            optionsCustomer: 'loan/applications/getCustomerTypes',
        }),
    },
    methods: {
        async getBanks() {
            let data = { skip: 1, take: 50 };
            const response = await this.$store.dispatch('general/banks/get_allBanks', data);
            this.banks = response?.dataResult ?? [];
        },
        resetForm() {
            this.bankSelected = null;
            this.obligationtype = '';
            this.obligationdate = '';
            this.amount = null;
            this.monthlypayment = null;
        },
        deleteObligation(index) {
            this.obligations.splice(index, 1);
            this.saveData();
        },
        handleOption(option) {
            console.log(option);
            this.customerType = option.id;
        },
        async saveData() {
            this.loading = true;
            await this.$store.dispatch('customer/screening/add_screeningVerification', {
                bankId: this.bankSelected,
                loanApplicationId: this.$route.params.id,
                customerId: this.customerType,
                amount: this.amount,
                montlyPayment: this.monthlypayment,
                obligationType: this.obligationtype,
                startDate: this.obligationdate || '1950-01-01T00:00:00.005Z',
                comment: this.comment,
                formIsCompleted: true,
                loanStatus: this.loanStatus,
            });

            if (this.isFromScreening) {
                this.$emit('refreshScreening');
            } else {
                await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.params.id);
            }
            this.$bvModal.hide('modal-add-obligation');
            this.loading = false;
        },
    },
    created() {
        this.getBanks();
    },
};
</script>
