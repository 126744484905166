import axios from 'axios';

const url = 'customers/Api/';

export default {
    namespaced: true,
    state() {
        return {
            documents: [],
            totalDocuments: 0,
            documentTypes: [],
        };
    },
    getters: {
        getDocuments(state) {
            return state.documents;
        },
        getTotalDocuments(state) {
            return state.totalDocuments;
        },
        getDocumentTypes(state) {
            return state.documentTypes;
        },
    },
    mutations: {
        SET_DOCUMENTS(state, documents) {
            state.documents = documents;
        },
        SET_TOTAL_DOCUMENTS(state, totalDocuments) {
            state.totalDocuments = totalDocuments;
        },
        SET_DOCUMENT_TYPES(state, documentTypes) {
            state.documentTypes = documentTypes;
        },
    },
    actions: {
        async get_documents({ commit }, payload) {
            try {
                const response = await axios.get(
                    `${url}ArchivedDocuments/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&customerId=${payload.customerId}`,
                );
                commit('SET_DOCUMENTS', response.data.dataResult);
                commit('SET_TOTAL_DOCUMENTS', response.data.dataRecords);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting documents', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_documentsById(_, payload) {
            try {
                const response = await axios.get(
                    `${url}ArchivedDocuments/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&customerId=${payload.customerId}`,
                );
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting documents', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_documentsByLoanAppId({ commit }, id) {
            try {
                const response = await axios.get(`customers/Api/ArchivedDocuments/GetByLoanApplicationId/${id}`);
                commit('SET_DOCUMENTS', response.data.dataResult);
                commit('SET_TOTAL_DOCUMENTS', response.data.dataRecords);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting documents', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async add_documents({ rootGetters }, payload) {
            try {
                const response = await axios.post(
                    `${url}ArchivedDocuments/Add?DocumentTypeId=${payload.documentTypeId}&CustomerId=${payload.customerId}&LoanApplicationId=${payload.loanApplicationId ?? 0}&documentDate=${payload.documentDate}&documentExpirationDate=${payload.documentExpirationDate}&CreatedBy=${rootGetters['auth/getUser'].Id}`,
                    payload.formData,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding document', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return false;
            }
        },
        async add_documentsInCDN({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}ArchivedDocuments/AddInCDN`, {
                    fileName: payload.fileName,
                    file: payload.file,
                    documentTypeId: payload.documentTypeId,
                    customerId: payload.customerId,
                    loanApplicationId: payload.loanApplicationId ?? 0,
                    createBy: rootGetters['auth/getUser'].Id,
                    documentDate: payload.documentDate,
                    documentExpirationDate: payload.documentExpirationDate,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding document', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return false;
            }
        },

        async get_documentTypes({ commit }, payload) {
            try {
                const response = await axios.get(
                    `${url}DocumentTypes/GetAll?Page=${payload?.skip || 1}&RecordsPerPage=${payload?.take ?? 100}`,
                );
                commit('SET_DOCUMENT_TYPES', response.data.dataResult);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting document types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_documentStatus({ rootGetters }, payload) {
            try {
                const response = await axios.put(`${url}ArchivedDocuments/UpdateStatus`, {
                    //Id, ModifiedBy, IsActive
                    Id: payload.id,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: payload.isActive,
                    Name: payload.name,
                    SourcePath: payload.sourcePath,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating document status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async remove_document(_, id) {
            try {
                const response = await axios.put(`${url}ArchivedDocuments/Remove/${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error deleting document', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_documentSignedById(_, id) {
            try {
                const response = await axios.get(`${url}ArchivedDocuments/GetDocumentSignedById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting document', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
