import axios from "axios";

export default {
	namespaced: true,
	state() {
		return {
			additionalDetails: null,
		};
	},
	getters: {
		getAdditionalDetail(state) {
			return state.additionalDetails;
		},
	},
	mutations: {
		SET_ADDITIONAL(state, data) {
			state.additionalDetails = data;
		},
	},
	actions: {
		async get_additionalDetailById({ commit }, id) {
			try {
				const response = await axios.get(`/customers/Api/AdditionalDetails/GetById/${id}`);
				commit("SET_ADDITIONAL", response.data.dataResult);
				return response.data.dataResult;
			} catch (error) {
				return null;
			}
		},
		async add_additionalDetail({ rootGetters }, { loanApplicationId, data }) {
			try {
				const response = await axios.post(`/customers/Api/AdditionalDetails/Add`, {
					IdentificationTypeId1: 2,
					IdentificationType1: data.identificationType1,
					IdentificationTypeId2: 3,
					IdentificationType2: data.identificationType2,
					LoanApplicationId: loanApplicationId,
					CustomerId: data.customerId,
					PlaceOfBirthId: data.placeOfBirthId,
					NationalityId: data.nationalityId,
					BirthDate: data.birthDate,
					BankId: data.bankId,
					BankAccountNumber: data.bankAccountNumber,
					Gender: data.gender,
					MaritalStatusId: data.maritalStatusId,
					SpousesFullName: data.spousesFullName,
					ExistingBankDebt: data.existingBankDebt,
					ExistingDebtAmount: data.existingDebtAmount,
					OtherInstitutionName: data.otherInstitutionName,
					CreatedBy: rootGetters["auth/getUser"].Id,
					CoApplicantPhone1: data.coApplicantPhone1,
					CoApplicantFirstName: data.coApplicantFirstName,
					CoApplicantLastName: data.coApplicantLastName,
					IsActive: true,
				});
				this._vm.$bvToast.toast("Additional details added successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async update_additionalDetail({ rootGetters }, { id, data }) {

			try {
				const response = await axios.put(`/customers/Api/AdditionalDetails/Update`, {
					IdentificationTypeId1: 2,
					IdentificationType1: data.identificationType1,
					IdentificationTypeId2: 3,
					IdentificationType2: data.identificationType2,
					LoanApplicationId: id,
					CustomerId: data.customerId,
					id: data.id,
					PlaceOfBirthId: data.placeOfBirthId,
					NationalityId: data.nationalityId,
					BirthDate: data.birthDate,
					BankId: data.bankId,
					BankAccountNumber: data.bankAccountNumber,
					Gender: data.gender,
					MaritalStatusId: data.maritalStatusId,
					SpousesFullName: data.spousesFullName,
					ExistingBankDebt: data.existingBankDebt,
					ExistingDebtAmount: data.existingDebtAmount,
					OtherInstitutionName: data.otherInstitutionName,
					ModifiedBy: rootGetters["auth/getUser"].Id,
					CoApplicantPhone1: data.coApplicantPhone1,
					CoApplicantFirstName: data.coApplicantFirstName,
					CoApplicantLastName: data.coApplicantLastName,
					NumberDependentPersons: data.numberDependentPersons,
					IsActive: true,
				});
				this._vm.$bvToast.toast("Additional details updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating additional details", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
