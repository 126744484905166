import Vue from 'vue';
import Router from 'vue-router';
import { jwtDecode } from 'jwt-decode';

import customerRoutes from './customer.route';
import applicationRoutes from './applications.route';
import generalRoutes from './general.route';
import securityRoutes from './security.route';
import authRoutes from './auth.route';
import mainRoutes from './main.route';
import affiliatesRoute from './affiliates.route';
import loansRoute from './loans.route';
import productsRoute from './products.route';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/login',
            component: () => import('@/layouts/full-layout/FullLayout'),
            children: [
                ...mainRoutes,
                ...customerRoutes,
                ...applicationRoutes,
                ...generalRoutes,
                ...securityRoutes,
                ...affiliatesRoute,
                ...loansRoute,
                ...productsRoute,
                {
                    name: 'Verifications',
                    path: 'verifications',
                    redirect: { name: 'screening-verifications' },
                    component: () => import('@/layouts/blank-layout/Blanklayout'),
                    children: [
                        {
                            name: 'Screenings Requests',
                            path: 'screening-requests',
                            component: () => import('@/views/verifications/ScreeningRequests'),
                            meta: {
                                requiresAuth: true,
                                requiredPermissions: ['/Banks-GetAll'],
                            },
                        },
                        {
                            name: 'Screenings Verifications',
                            path: 'screening-verifications',
                            component: () => import('@/views/verifications/ScreeningVerifications'),
                            meta: {
                                requiresAuth: true,
                                requiredPermissions: ['/Banks-GetAll'],
                            },
                        },
                        {
                            name: 'Job Verifications',
                            path: 'job-verifications',
                            component: () => import('@/views/verifications/JobVerifications'),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                    ],
                },
            ],
        },
        ...authRoutes,
        {
            path: '/screening-verification/:guid',
            component: () => import('@/views/screening-verification/ScreeningVerification'),
        },
        {
            path: '/email-verification/:id',
            component: () => import('@/views/email-verification/EmailVerification'),
        },

        {
            path: '*',
            component: () => import('@/views/authentication/Error'),
        },
    ],
});

import NProgress from 'nprogress';

router.beforeResolve((to, from, next) => {
    const token = localStorage.getItem('token');
    //validate expired token
    if (token) {
        const user = jwtDecode(token);

        const expirationDate = new Date(user.exp * 1000);
        const currentDate = new Date();

        if (expirationDate < currentDate) {
            localStorage.removeItem('token');
            next('/login');
        }
    }

    if (to.matched.some((route) => route.meta.requiresAuth) && !token) {
        next('/login');
    } else if (to.path === '/login' && token) {
        next('/home');
    }

    if (to.meta.requiredPermissions) {
        const user = jwtDecode(token);
        const session = JSON.parse(user.Session);
        const permissions = session.Authorizations;
        const requiredPermissions = to.meta.requiredPermissions;
        const hasPermission = requiredPermissions.every((permission) => permissions.includes(permission));

        if (!hasPermission) {
            return next('/access-denied');
        }
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
