import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            scans: [],
        };
    },
    getters: {
        getScans(state) {
            return state.scans;
        },
    },
    mutations: {
        SET_SCANS(state, scans) {
            state.scans = scans;
        },
    },
    actions: {
        async createReview({ rootGetters }, payload) {
            try {
                const response = await axios.post(`customers/Api/Scans/CreateReview`, {
                    dataBreachCheckParam: {
                        emailAddress: payload.email,
                    },
                    FirstName: payload.firstName,
                    LastName: payload.lastName,
                    MiddleName: payload.middleName,
                    CustomerId: payload.customerId,
                    createdBy: rootGetters['auth/getUser'].Id,
                });

                this._vm.$bvToast.toast('Review created successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating review', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async completeReview({ rootGetters }, payload) {
            try {
                const response = await axios.put(`customers/Api/Scans/CompleteReview`, {
                    ...payload,
                    completedBy: rootGetters['auth/getUser'].Id,
                });

                this._vm.$bvToast.toast('Review completed successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error completing review', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async updateReview({ rootGetters }, payload) {
            try {
                const response = await axios.put(`customers/Api/Scans/UpdateReview`, {
                    ...payload,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });

                this._vm.$bvToast.toast('Review updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating review', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_scans({ commit }, payload) {
            try {
                const response = await axios.get(`customers/Api/Scans/GetAllVerificationRequest`, {
                    params: {
                        Page: 1,
                        RecordsPerPage: 999,
                        CustomerId: payload.customerId,
                        FirstName: payload.firstName,
                        LastName: payload.lastName,
                        Email: '',
                    },
                });
                commit('SET_SCANS', response.data.dataResult);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting scans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_scanById(_, payload) {
            try {
                const response = await axios.get(`customers/Api/Scans/GetByCustomerId?CustomerId=${payload}`);

                return response?.data && response.data != '' ? response.data : [];
            } catch (error) {
                this._vm.$bvToast.toast('Error getting scan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_creditScoreRules() {
            try {
                const response = await axios.get(`customers/Api/Scans/CreditScoreRules/GetAll`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting credit score rules', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async getReviewReportSignedById(_, payload) {
            try {
                const response = await axios.get(`customers/Api/Scans/GetReviewReportSignedById/${payload}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting review report', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
