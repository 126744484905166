<template>
    <div>
        <b-card no-body class="mb-4">
            <template v-if="!readOnly">
                <Header />
                <hr class="mb-2" />
            </template>
            <!-- Search Control -->
            <div class="row">
                <div class="col-4">
                    <p>
                        <strong>Last screening completition date:</strong>
                        {{ $formatDate(screeningsBatch.lastCompletionDate) }}
                    </p>
                    <p><strong>Days since last screening date:</strong> {{ daysLastScreeningDate }}</p>
                    <p><strong>Requires new screening:</strong> {{ requiresNewScreening }}</p>
                </div>

                <div class="col-4">
                    <!-- <p><strong>Current submittion status</strong></p> -->
                    <p><strong>Screening send date:</strong> {{ $formatDate(screeningsBatch.date) }}</p>
                    <p>
                        <strong>Screening status: </strong>

                        <b-badge :variant="screningStatusColor(screeningsBatch.state)">{{
                            screeningsBatch.state
                        }}</b-badge>
                    </p>
                </div>
                <div class="col-4 d-flex justify-content-end align-items-start">
                    <b-button
                        variant="primary"
                        class="mr-2"
                        :disabled="loadingSendVerifications"
                        @click="validateSubmitToVerification"
                        v-if="$validatePermission('/ScreeningsRequest-SubmitForVerifications')"
                    >
                        <b-spinner small v-if="loadingSendVerifications" class="mr-2" />
                        <span>Submit for screening</span>
                    </b-button>
                </div>
            </div>

            <div class="mb-2 d-flex justify-content-between">
                <b-form-group class="mb-2 w-25">
                    <b-input-group>
                        <b-form-input
                            size="sm"
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <!-- End Section -->

            <!-- Data Table -->
            <b-table
                style="min-height: 250px"
                show-empty
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                small
                class="mb-0"
                head-variant="light"
                :items="screenings"
                :filter="filter"
                :fields="fields"
                hover
            >
                <!-- <template #cell(id)="data"> #{{ data.item.id }} </template> -->
                <template #cell(createDate)="data"> {{ $formatDate(data.item.createDate) }} </template>
                <template #cell(modifiedDate)="data"> {{ $formatDate(data.item.modifiedDate) }} </template>
                <template #cell(amount)="data"> {{ $formatCurrency(data.item.amount) }} </template>
                <template #cell(montlyPayment)="data"> {{ $formatCurrency(data.item.montlyPayment) }} </template>
                <template #cell(formIsCompleted)="data">
                    <b-badge variant="success" v-if="data.item.formIsCompleted">Completed</b-badge>
                    <b-badge variant="danger" v-else>Pending</b-badge>
                </template>
                <template #cell(action)>
                    <!-- <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                        <b-dropdown-item @click="showEditModal(data)">Edit Reference</b-dropdown-item>
                    </b-dropdown> -->
                </template>
            </b-table>
            <!-- End Section -->

            <!-- Pagination -->
            <div class="p-3 d-flex align-items-center">
                <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="screenings.length"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
            <!-- End Section -->

            <b-modal id="modal-add-screening" hide-footer centered title="Financial Obligations" size="md">
                <b-card no-body>
                    <p>Are you sure you want to add a new screening for this customer?</p>
                    <div class="d-flex justify-content-end">
                        <b-button variant="outline-secondary" @click="closeModal" class="mr-2">Cancel</b-button>
                        <!-- <b-button variant="primary" :disabled="loading" @click="saveData">
                            <b-spinner small v-if="loading"></b-spinner>
                            <span v-else>New Screening</span>
                        </b-button> -->
                    </div>
                </b-card>
            </b-modal>
        </b-card>

        <!-- Modal with to ask spouses to screenig request -->
        <b-modal
            v-if="spouse"
            id="modal-confirm-spouses"
            hide-footer
            centered
            title="Confirm Screening Submission"
            size="md"
        >
            <b-card no-body v-if="spouse">
                <p class="mb-1">You're about to submit this client for screening.</p>
                <p class="mb-3">
                    <strong>Spouse: </strong>{{ spouse.firstName }} {{ spouse.middleName }} {{ spouse.lastName }}
                </p>
                <p class="mb-1">
                    <strong>Last Screening Completion Date: </strong>
                    <template v-if="spouse.screenings.screeningsBatch">{{
                        $formatDate(spouse.screenings.screeningsBatch.lastCompletionDate)
                    }}</template>
                </p>
                <p class="mb-1"><strong>Days Since Last Screening:</strong> {{ spouseDaysLastScreeningDate }}</p>
                <p class="mb-1">
                    <strong>Requires New Screening:</strong>
                    {{ spouseRequiresNewScreening }}
                </p>
                <template>
                    <h5 class="mt-3">Submission Status</h5>
                    <p class="mb-1">
                        <strong>Screening Sent Date: </strong>
                        <template v-if="spouse.screenings.screeningsBatch">{{
                            $formatDate(spouse.screenings.screeningsBatch.date)
                        }}</template>
                    </p>
                    <p class="mb-1">
                        <strong>Screenig Status: </strong>
                        <b-badge
                            v-if="spouse.screenings.screeningsBatch"
                            :variant="spouse.screenings.screeningsBatch.state === 'Pending' ? 'danger' : 'success'"
                            >{{ screeningsBatch.state }}</b-badge
                        >
                    </p>
                </template>

                <b-form @submit.prevent="submitToVerification">
                    <label for="spouse" class="mt-4">Add spouse to screening?</label>
                    <!-- //radio with two options yes or no -->
                    <b-form-group>
                        <b-form-radio-group
                            id="spouse"
                            v-model="spouseSelection"
                            :options="[
                                {
                                    text: 'Yes',
                                    value: true,
                                },
                                {
                                    text: 'No',
                                    value: false,
                                },
                            ]"
                            required
                        ></b-form-radio-group>
                    </b-form-group>

                    <div class="d-flex justify-content-end">
                        <b-button variant="primary" :disabled="loadingSendVerifications" type="submit">
                            <b-spinner small v-if="loading"></b-spinner>
                            <span v-else>Submit</span>
                        </b-button>
                    </div>
                </b-form>
            </b-card>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
export default {
    name: 'CustomerScreening',
    components: {
        Header,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        filter: null,
        fields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'createDate',
                label: 'Request Date',
            },
            {
                key: 'bankDescription',
                label: 'Bank',
            },

            {
                key: 'formCompletedDate',
                label: 'Date Completed',
            },
            {
                key: 'amount',
                label: 'Balance',
            },
            {
                key: 'montlyPayment',
                label: 'Monthly Payment',
            },
            {
                key: 'loanStatus',
                label: 'Loan Status',
            },
            {
                key: 'formIsCompleted',
                label: 'Status',
            },
            {
                key: 'comment',
                label: 'Comments',
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        loadingSendVerifications: false,
        banks: [],
        bank: null,
        obligationtype: null,
        obligationdate: '',
        amount: null,
        monthlypayment: null,
        comment: '',
        loading: false,
        spouse: null,
        spouseSelection: null,
    }),
    methods: {
        async getSpouseInfo() {
            const spousesId = this.customer?.additionalDetails?.spousesId;
            if (spousesId) {
                const response = await this.$store.dispatch('customer/get_reference', spousesId);
                this.spouse = response;
            }
        },
        async submitToVerification() {
            this.loadingSendVerifications = true;
            await this.$store.dispatch('customer/screening/submit_toPendingRequests', {
                customerId: this.$route.params.customer,
                addSposesToScreenings: this.spouseSelection,
                sposesId: this.spouse?.id,
            });
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            this.loadingSendVerifications = false;
            this.$bvModal.hide('modal-confirm-spouses');
        },
        async getBanks() {
            let data = { skip: 1, take: 50 };
            const response = await this.$store.dispatch('general/banks/get_allBanks', data);
            this.banks = response?.dataResult ?? [];
        },

        validateSubmitToVerification() {
            if (this.spouse) {
                this.$bvModal.show('modal-confirm-spouses');
            } else {
                this.submitToVerification();
            }
        },

        clearValues() {
            this.bank = null;
            this.obligationtype = null;
            this.obligationdate = '';
            this.amount = null;
            this.monthlypayment = null;
            this.comment = '';
        },

        closeModal() {
            this.$bvModal.hide('modal-add-screening');
            this.clearValues();
        },

        screningStatusColor(status) {
            if (status === 'Completed') {
                return 'success';
            }
            if (status === 'Pending') {
                return 'secondary';
            }
            return 'danger';
        },

        async saveData() {
            this.loading = true;
            const payload = {
                customerId: this.$route.params.customer,
            };

            await this.$store.dispatch('customer/screening/add_screeningBankRecords', payload);
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);

            this.loading = false;
            this.$bvModal.hide('modal-add-screening');
            this.clearValues();
        },

        calculateDaysLastScreeningDate(lastCompletionDate) {
            if (!lastCompletionDate) {
                return 0;
            }
            const lastScreeningDate = new Date(lastCompletionDate);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - lastScreeningDate);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        },
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
        }),
        screenings() {
            return this.customer?.screenings?.screeningsRecords ?? [];
        },

        screeningsBatch() {
            return (
                this.customer?.screenings?.screeningsBatch || {
                    lastCompletionDate: null,
                    date: null,
                    state: '',
                }
            );
        },

        daysLastScreeningDate() {
            if (!this.screeningsBatch) return 0;
            return this.calculateDaysLastScreeningDate(this.screeningsBatch.lastCompletionDate);
        },

        spouseDaysLastScreeningDate() {
            if (!this.spouse?.screenings?.screeningsBatch) return 0;
            return this.calculateDaysLastScreeningDate(this.spouse.screenings.screeningsBatch.lastCompletionDate);
        },

        requiresNewScreening() {
            if (!this.customer?.screenings?.screeningsBatch) return 'Yes';
            if (this.daysLastScreeningDate == 0) {
                return 'No';
            }
            return this.daysLastScreeningDate > 14 ? 'Yes' : 'No';
        },
        spouseRequiresNewScreening() {
            if (!this.spouse?.screenings?.screeningsBatch) return 'Yes';
            if (this.spouseDaysLastScreeningDate == 0) {
                return 'No';
            }
            return this.spouseDaysLastScreeningDate > 14 ? 'Yes' : 'No';
        },
    },
    created() {
        this.getBanks();
        this.getSpouseInfo();
    },
};
</script>
